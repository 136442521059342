<template>
  <div>
    <div v-if="phasesDistinct && phasesDistinct.length > 2">
      <v-row
        ><v-col cols="12" md="10"
          ><b>Project Summary Phase {{ selectedPhase }}</b></v-col
        ><v-col>
          <v-select
            v-model="selectedPhase"
            :items="phasesDistinct"
            label="Select Phase"
            dense
            @change="changedPhase()"
          ></v-select></v-col
      ></v-row>
    </div>

    <v-row align="stretch" justify="center">
      <v-col cols="10">
        <v-card flat v-if="statusOverviewLatestQuery">
          <v-card-title>
            <span
              v-for="stat in statusOverviewLatestQuery.response"
              :key="stat.id"
            >
              <v-chip
                @click="showList(stat)"
                class="ma-2"
                :color="stat.Color"
                :text-color="applyDark(stat.Color) ? 'white' : 'black'"
              >
                <b class="mr-3"> {{ stat.Count }}</b>
                {{ stat.Status }}
                <b class="mr-3 ml-2">
                  - {{ (stat.Procent * 100).toFixed(0) }}%
                </b>
              </v-chip>
            </span>
          </v-card-title>
        </v-card>
        <span v-else
          ><v-progress-circular
            indeterminate
            :size="15"
            :width="1"
          ></v-progress-circular
        ></span>
      </v-col>
      <v-col cols="2">
        <v-card
          flat
          height="100%"
          v-if="statusOverviewLatestQuery && statusOverviewLatestQuery.response"
        >
          <v-card-title
            v-if="
              statusOverviewLatestQuery.response.length > 0 &&
              statusOverviewLatestQuery.response[0].Progress > 0
            "
            class="justify-center"
          >
            <v-progress-circular
              :rotate="-90"
              :size="95"
              :width="12"
              :value="statusOverviewLatestQuery.response[0].Progress * 100"
              color="black"
            >
              {{
                statusOverviewLatestQuery.response[0].Progress == 1
                  ? (
                      statusOverviewLatestQuery.response[0].Progress * 100
                    ).toFixed(0)
                  : (
                      statusOverviewLatestQuery.response[0].Progress * 100
                    ).toFixed(2)
              }}%
            </v-progress-circular>
          </v-card-title>
          <v-card-title v-else class="justify-center"> --.--% </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" width="1200">
      <ItemsPerStatus
        v-model="showDialog"
        :status="selectedStatus"
        :phase="selectedPhase"
    /></v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import ItemsPerStatus from './ItemsPerStatus';

import { applyDark } from '@/utils/Utilities';

export default {
  data() {
    return {
      selectedPhase: null,
      selectedStatus: null,
      showDialog: false,
    };
  },
  components: { ItemsPerStatus },
  watch: {
    selectedPhase() {
      const { StatusOverview } = this.$FeathersVuex.api;

      StatusOverview.find(this.phaseQuery);
    },
  },
  methods: {
    applyDark,
    changedPhase() {
      window.localStorage.setItem('phase', this.selectedPhase);
    },
    showList(stat) {
      this.selectedStatus = stat;
      this.showDialog = true;
    },
  },

  mixins: [
    makeFindMixin({ service: 'status-overview', watch: 'selectedPhase' }),
    makeFindMixin({ service: 'process', name: 'phases' }),
  ],
  computed: {
    phasesParams() {
      return {
        query: {
          $select: ['Phase'],
        },
      };
    },
    phasesDistinct() {
      if (this.phases && this.phases.length > 0) {
        let phases = [...this.phases];
        phases = phases.map((p) => p.Phase);
        phases = [...new Set(phases)];
        phases = phases.sort();
        phases.unshift('All');

        let storedPhase = window.localStorage.getItem('phase');
        if (phases && storedPhase && !phases.includes(parseInt(storedPhase))) {
          // check if an incorrect phase is stored in localstorage
          window.localStorage.removeItem('phase');
        }

        return phases;
      } else return [];
    },
    phaseQuery() {
      if (this.selectedPhase && this.selectedPhase != 'All') {
        return {
          query: {
            phase: this.selectedPhase,
          },
        };
      } else {
        return {};
      }
    },
    statusOverviewParams() {
      return this.phaseQuery;
    },
  },
  mounted() {
    const { StatusOverview } = this.$FeathersVuex.api;

    this.selectedPhase = window.localStorage.getItem('phase')
      ? parseInt(window.localStorage.getItem('phase'))
      : 'All';
    StatusOverview.find(this.phaseQuery);
  },
};
</script>

<style></style>
