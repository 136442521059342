import { shallowPopulate } from 'feathers-shallow-populate';

import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class Specification extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Specification';
  // Define default properties here
  static instanceDefaults() {
    return {
      Number: 0,
      Description1: null,
      Description2: null,
      Description3: null,
      Description4: null,
      Obsolete: false,
      PriorityId: 1,
      StatusId: 1,
    };
  }
}
const servicePath = 'specification';
const servicePlugin = makeServicePlugin({
  Model: Specification,
  service: feathersClient.service(servicePath),
  servicePath,
});

const options = {
  include: [
    {
      service: 'status',
      nameAs: 'status',
      keyHere: 'StatusId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'priority',
      nameAs: 'priority',
      keyHere: 'PriorityId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
    {
      service: 'response',
      nameAs: 'responses',
      keyHere: 'id',
      keyThere: 'SpecificationId',
      asArray: true, // by default
      params: {}, // by default
    },
    {
      service: 'specification-type',
      nameAs: 'specification_type',
      keyHere: 'SpecificationTypeId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
  ],
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [shallowPopulate(options)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
