var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('FieldEditDialog',{attrs:{"item":clone},on:{"save":function($event){_vm.showDialog = false;
        save()
          .then(async (saved) => {
            _vm.handleSaveResponse(
              saved.Name,
              'field',
              _vm.currentItem ? 'updated' : 'created'
            );
            const { FieldStep } = _vm.$FeathersVuex.api;
            let exists = await FieldStep.find({
              query: {
                FieldId: saved.id,
                ProcessStepId: saved.ProcessStepId,
              },
            });
            if (exists.data.length == 0 && saved.ProcessStepId != null) {
              let newRecord = new FieldStep();
              newRecord.ProcessStepId = saved.ProcessStepId;
              newRecord.FieldId = saved.id;
              newRecord.save().then(async (saved2) => {
                _vm.handleSaveResponse(saved2.id, 'field step', 'saved');
                await _vm.refresh();
              });
            }
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
          });},"reset":reset,"remove":function($event){remove()
          .then((saved) => _vm.handleSaveResponse(saved.Name, 'field', 'removed'))
          .catch((error) => _vm.handleErrorResponse(error));
        _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}})]}}],null,false,1302106788)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }