<template>
  <v-container
    ><h1>Import Process</h1>
    <div v-if="showFrame" class="fullheight-container">
      <iframe
        class="full-width"
        frameBorder="0"
        :src="`https://lucid.app/documents/picker?token=${TokenEmbed}`"
      ></iframe>
    </div>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="secondary"
    ></v-progress-linear>
    <v-stepper v-model="e6" vertical v-if="event && !loading">
      <v-stepper-step :complete="e6 > 1" step="1">
        Import Process
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card class="mb-12" autoheight>
          <div
            v-if="
              existingProcess &&
                existingProcess.data.length == 0 &&
                pages.length > 0
            "
          >
            <v-row dense>
              <v-col cols="12" md="12">
                <ProcessEditDialog
                  v-if="!linkExistingProcess"
                  :item="process"
                  :importProcess="true"
                  :pages="pages"/></v-col
            ></v-row>
            <v-row dense
              ><v-col>
                <!-- Allow linking to existing -->
                <v-checkbox
                  v-model="linkExistingProcess"
                  label="Link to existing process"
                ></v-checkbox>
              </v-col> </v-row
            ><v-row dense
              ><v-col>
                <!-- Show list of all processes -->
                <v-autocomplete
                  v-if="linkExistingProcess"
                  v-model="processToLink"
                  :items="fullProcessList"
                  item-text="Name"
                  item-value="id"
                  dense
                  outlined
                  label="Select Process"
                >
                </v-autocomplete></v-col
              ><v-col>
                <!-- When clicking Link button merge objects and show edit dialog -->
                <v-btn
                  v-if="linkExistingProcess"
                  color="primary"
                  @click="linkProcess()"
                  >Link process</v-btn
                ></v-col
              ></v-row
            ><v-row dense
              ><v-col>
                <ProcessEditDialog
                  v-if="showEditLinkDialog && linkExistingProcess"
                  :item="editProcessToLink"
                  :importProcess="true"
                  :pages="pages"/></v-col></v-row
            ><v-row dense
              ><v-col>
                <!-- Save button saves, skip button skips -->

                <v-btn
                  v-if="
                    !linkExistingProcess ||
                      (linkExistingProcess && editProcessToLink)
                  "
                  color="primary"
                  @click="clickSaveProcess()"
                >
                  Save Process
                </v-btn>
              </v-col></v-row
            >
          </div>
          <div v-if="existingProcess && existingProcess.data.length > 0">
            <v-alert
              border="bottom"
              colored-border
              type="warning"
              elevation="2"
            >
              Process already exists.
            </v-alert>
            <v-btn text outlined @click="clickSkipProcess()">
              Skip
            </v-btn>
          </div></v-card
        >
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 2" step="2">
        Import Variants
      </v-stepper-step>

      <v-stepper-content step="2">
        <v-card class="mb-12" autoheight>
          <v-row dense>
            <v-col
              v-for="(variant, index) in variants"
              :key="index"
              cols="12"
              md="6"
            >
              <VariantEditDialog
                :item="variant"
                :importVariant="true"
                :pages="pages"
              />
              <v-row align="center" justify="center" dense>
                <v-col v-if="!variant.existingVariant" cols="12" md="2">
                  <v-icon @click="removeVariant(index)">mdi-trash-can</v-icon>
                </v-col>

                <v-col cols="12" md="10">
                  <v-alert
                    v-if="variant.existingVariant"
                    border="bottom"
                    colored-border
                    type="warning"
                    elevation="2"
                  >
                    Variant already exists. (Skipped)
                  </v-alert>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          v-if="variants.filter(v => !v.existingVariant).length > 0"
          color="primary"
          @click="clickSaveVariant()"
        >
          Save Variants
        </v-btn>
        <v-btn
          v-if="variants.filter(v => !v.existingVariant).length == 0"
          text
          outlined
          @click="clickSkipVariants()"
        >
          Skip
        </v-btn>
        <v-btn text outlined>
          Cancel
        </v-btn>
      </v-stepper-content>

      <v-stepper-step :complete="e6 > 3" step="3">
        Import Process Steps
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card
          v-for="variant in variants"
          :key="variant.id"
          class="mb-12"
          autoheight
        >
          {{ variant.Name }}
          <ul>
            <li
              v-for="(step, index) in processSteps.filter(
                i =>
                  i.VariantId ==
                  (variant.existingVariant
                    ? variant.existingVariant.id
                    : variant.id)
              )"
              :key="step.id"
            >
              {{ step.Number }}. {{ step.Name }}
              <v-icon small color="orange" v-if="step.existingStep"
                >mdi-alert-outline</v-icon
              ><v-icon v-if="!step.existingStep" @click="removeStep(index)"
                >mdi-trash-can</v-icon
              >
            </li>
          </ul>
        </v-card>
        <v-btn color="primary" @click="clickSaveSteps()">
          Save Process Steps
        </v-btn>
        <v-btn text outlined @click="clickCancelSteps()">
          Cancel
        </v-btn>
      </v-stepper-content>
    </v-stepper>
  </v-container></template
>

<script>
import {
  getDocumentPickerToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import ProcessEditDialog from '@/components/Process/ProcessEditDialog.vue';
import VariantEditDialog from '@/components/Variant/VariantEditDialog.vue';

export default {
  mixins: [
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'process', name: 'fullProcessList' }),
  ],
  components: { ProcessEditDialog, VariantEditDialog },
  data() {
    return {
      loading: false,
      TokenEmbed: null,
      showFrame: true,
      event: null,
      parsedVariants: null,
      parsedSteps: null,
      process: null,
      existingProcess: null,
      variants: [],
      steps: [],
      pages: [],
      activeProcessId: null,
      e6: 1,
      linkExistingProcess: false,
      processToLink: null,
      editProcessToLink: null,
      showEditLinkDialog: false,
      existingVariants: [],
      processSteps: [],
    };
  },

  computed: {
    departmentParams() {
      return {};
    },
    fullProcessListParams() {
      return {};
    },
    processNumber() {
      let number = parseInt(this.event.title.substring(0, 4));
      return number;
    },
    processTitle() {
      let title = this.event.title
        .substring(5, 9999)
        .replace(/(^\W*)|(\W*$)/g, '');
      return title;
    },
    departmentImported() {
      if (this.processNumber && this.department) {
        let numberRounder = Math.floor(this.processNumber / 100) * 100;
        const zeroPad = (num, places) => String(num).padStart(places, '0');
        numberRounder = zeroPad(numberRounder, 4);
        let result = this.department.filter(
          item => item.CustomerDepartment == numberRounder
        );
        return result;
      } else return null;
    },
  },

  methods: {
    async receiveMessage(event) {
      // console.log('window load event', event);
      if (event && event.data.status == 'success') {
        this.loading = true;
        this.event = event.data;
        this.showFrame = false;
        const { Process } = this.$FeathersVuex.api;
        this.process = new Process();
        this.process.Number = parseInt(this.event.title.substring(0, 4));
        this.process.Name = this.event.title
          .substring(5, 9999)
          .replace(/(^\W*)|(\W*$)/g, '');
        this.process.ExternalDocumentId = this.event.documentId;
        this.process.DepartmentId = this.departmentImported[0].id;
        this.process.StatusId = 1;

        this.existingProcess = await Process.find({
          query: { ExternalDocumentId: this.event.documentId },
        });
        if (this.existingProcess.data.length > 0) {
          this.activeProcessId = this.existingProcess.data[0].id;
        }

        let result = await getDocumentInfo(null, this.event.documentId);
        let parsed = parseDocumentInfo(result);

        this.pages = [...parsed];
        this.process.SelectedPageIndexes = [0];

        // Only use the first page to get all the variants
        this.parsedVariants = parsed.shift();
        // console.log(parsedVariants);

        this.parsedSteps = parsed;
        this.loading = false;
      }
    },
    async getVariants() {
      const { Variant } = this.$FeathersVuex.api;

      // Get imported Variants
      this.parsedVariants.blocks.forEach(async (variant, index) => {
        let newVariant = new Variant();
        newVariant.Number = index + 1;
        newVariant.Name = variant.text;
        newVariant.ExternalId = variant.id;
        newVariant.ApplicationId = 1;
        newVariant.ExtraPages = '';

        let findPage = this.pages.filter(p => {
          // console.log('p', p);
          let nbr = p.title.split('.');
          nbr = parseInt(nbr[1]);
          return nbr == index + 1;
        });
        // console.log(findPage);
        newVariant.SelectedPageIndexes = [
          findPage.length > 0 ? findPage[0].index : null,
        ]; //[index + 1];

        // Match variants on external id and insert existing variant into variant object as subnode
        let variantsearch = await Variant.find({
          query: {
            ExternalId: variant.id,
          },
        });

        variantsearch.total > 0
          ? (newVariant.existingVariant = { ...variantsearch.data[0] })
          : (newVariant.existingVariant = null);
        this.variants.push(newVariant);
      });
    },
    async getSteps() {
      const { ProcessStep } = this.$FeathersVuex.api;
      this.parsedSteps.forEach(async steps => {
        // Get existing Variants to find linked pageindex
        let selectedVariant = this.variants.filter(v => {
          if (v.existingVariant) {
            return (
              (v.existingVariant.PageIndexes
                ? v.existingVariant.PageIndexes.split(',')[0] - 1
                : null) == steps.index
            );
          } else {
            return (
              (v.PageIndexes ? v.PageIndexes.split(',')[0] - 1 : null) ==
              steps.index
            );
          }
        });

        if (selectedVariant.length > 0) {
          steps.blocks.forEach(async (step, index) => {
            // console.log('step', step);
            let newStep = new ProcessStep();
            newStep.VariantId = selectedVariant[0].id
              ? selectedVariant[0].id
              : selectedVariant[0].existingVariant.id;
            newStep.Name = step.text;
            newStep.Number = index + 1;
            newStep.ExternalId = step.id;
            newStep.StatusId = 1;
            newStep.PriorityId = 1;

            // Match steps on external id and insert existing step into variant object as subnode
            let stepsearch = await ProcessStep.find({
              query: {
                ExternalId: step.id,
              },
            });

            stepsearch.total > 0
              ? (newStep.existingStep = { ...stepsearch.data[0] })
              : (newStep.existingStep = null);

            this.processSteps.push(newStep);
          });
        }
      });
      // All steps are parsed, remove them to prevent parsing them again
      this.parsedSteps = null;
    },
    async clickSaveProcess() {
      if (this.editProcessToLink && this.linkExistingProcess) {
        let pageindex = this.process.SelectedPageIndexes.map(item => item + 1);
        pageindex = String(pageindex);
        //Removed already linked pages
        this.pages = this.pages.filter(p => {
          return !this.process.SelectedPageIndexes.includes(p.index);
        });

        feathersClient
          .service('process')
          .patch(this.editProcessToLink.id, {
            ExternalDocumentId: this.editProcessToLink.ExternalDocumentId,
            PageIndexes: pageindex,
          })
          .then(saved => {
            this.$toasted.global.success(
              `Process ${saved.Name} saved successfully`
            );
            this.activeProcessId = saved.id;
          })
          .catch(error => {
            // console.log(error);
            let errorMessage = '';
            error.errors.map(item => {
              errorMessage += item.message + '\n';
            });
            this.errorMessage = errorMessage;
            this.$toasted.global.error(errorMessage);
          });
      } else {
        let pageindex = this.process.SelectedPageIndexes.map(item => item + 1);
        this.process.PageIndexes = String(pageindex);
        //Removed already linked pages
        this.pages = this.pages.filter(p => {
          return !this.process.SelectedPageIndexes.includes(p.index);
        });
        this.process
          .save()
          .then(saved => {
            this.$toasted.global.success(
              `Process ${saved.Name} saved successfully`
            );
            this.activeProcessId = saved.id;
          })
          .catch(error => {
            // console.log(error);
            let errorMessage = '';
            error.errors.map(item => {
              errorMessage += item.message + '\n';
            });
            this.errorMessage = errorMessage;
            this.$toasted.global.error(errorMessage);
          });
      }
      await this.getVariants();
      this.e6 = 2;
    },
    async clickSkipProcess() {
      // Remove pages currently linked to process
      if (this.existingProcess.data[0].PageIndexes) {
        let pageIndexes = this.existingProcess.data[0].PageIndexes.split(',');
        pageIndexes = pageIndexes.map(i => parseInt(i));
        this.pages = this.pages.filter(p => {
          return !pageIndexes.includes(p.index);
        });
      }
      await this.getVariants();
      this.e6 = 2;
    },
    clickSaveSteps() {
      //Filter out existing variants
      this.processSteps = this.processSteps.filter(step => !step.existingStep);
      this.processSteps.forEach(step => {
        step
          .save()
          .then(saved => {
            this.$toasted.global.success(
              `Process Step ${saved.Name} saved successfully`
            );
          })
          .catch(error => {
            // console.log(error);
            let errorMessage = '';
            error.errors.map(item => {
              errorMessage += item.message + '\n';
            });
            this.errorMessage = errorMessage;
            this.$toasted.global.error(errorMessage);
          });
      });

      this.e6 = 4;
      setTimeout(() => {
        this.event = null;
        this.process = null;
        this.variants = [];
        this.processSteps = [];
        this.existingProcess = null;
        this.showFrame = true;
        this.e6 = 1;
      }, 1000);
    },
    clickCancelSteps() {
      this.e6 = 4;
      setTimeout(() => {
        this.event = null;
        this.process = null;
        this.variants = [];
        this.processSteps = [];
        this.existingProcess = null;
        this.showFrame = true;
        this.e6 = 1;
      }, 1000);
    },
    removeVariant(index) {
      this.variants.splice(index, 1);
    },
    removeStep(index) {
      this.processSteps.splice(index, 1);
    },
    async clickSaveVariant() {
      //Filter out existing variants
      this.variants = this.variants.filter(variant => !variant.existingVariant);
      this.variants.forEach(variant => {
        variant.ProcessId = this.activeProcessId;
        variant.SelectedPageIndexes = variant.SelectedPageIndexes.filter(
          item => item !== null
        );
        // console.log('pageindex after filter', pageindex);
        let pageindex = variant.SelectedPageIndexes.map(item => item + 1);

        variant.PageIndexes = String(pageindex);
        // console.log('pageindex', pageindex);
        variant
          .save()
          .then(async saved => {
            variant.SavedId = saved.id;
            this.$toasted.global.success(
              `Variant ${saved.Name} saved successfully`
            );
            const { Process } = this.$FeathersVuex.api;
            await Process.find();
            await this.getSteps();
          })
          .catch(error => {
            // console.log(error);
            let errorMessage = '';
            error.errors.map(item => {
              errorMessage += item.message + '\n';
            });
            this.errorMessage = errorMessage;
            this.$toasted.global.error(errorMessage);
          });
      });

      this.e6 = 3;
    },
    async clickSkipVariants() {
      await this.getSteps();
      this.e6 = 3;
    },
    linkProcess() {
      this.editProcessToLink = this.fullProcessList.filter(
        item => item.id == this.processToLink
      )[0];
      this.editProcessToLink.ExternalDocumentId = this.process.ExternalDocumentId;
      this.editProcessToLink.SelectedPageIndexes = this.process.SelectedPageIndexes;
      this.showEditLinkDialog = true;
    },
  },
  async mounted() {
    this.TokenEmbed = await getDocumentPickerToken(location.origin);
    window.addEventListener('message', this.receiveMessage);
  },
};
</script>

<style scoped>
.fullheight-container {
  max-width: 100%;
  max-height: 100%;
  width: 100vw;
  height: 75vh;
  overflow: hidden;
  align-items: center;
}

.full-width {
  min-width: 100%;
  min-height: 100%;
}
</style>
