var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('TestScenarioEditDialog',{attrs:{"item":clone,"tests":_vm.tests,"processStep":_vm.processStep},on:{"save":function($event){_vm.showDialog = false;
        _vm.saveItem(save, clone);},"reset":reset,"remove":function($event){remove()
          .then(async (saved) => {
            _vm.handleSaveResponse(saved.Description, 'test scenario', 'removed');

            await _vm.refresh();
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
          });

        _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}})]}}],null,false,4000005741)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }