<template>
  <v-card width="100vw" flat>
    <v-data-table
      :headers="headers"
      :items="filteredSpecification"
      :items-per-page="-1"
      item-key="id"
      :loading="isFindSpecificationPending"
      class="elevation-1"
    >
      <template v-slot:[`item.variants`]="{ item }">
        <span class="caption" v-if="item.variants && item.variants.length > 0"
          >{{ item.variants[0].process.Name }}/
          <a
            :href="'/#/processStep/' + item.variants[0].id"
            v-if="item.variants && item.variants.length > 0"
            >{{ item.variants[0].Name }}</a
          ></span
        >
      </template>
      <template v-slot:[`item.Description1`]="{ item }">
        <span
          >{{ item.specification_type.Code
          }}{{ item.Number.toString().padStart(4, '0') }}
          {{ item.Description1 }}</span
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
export default {
  props: ['status', 'phase'],
  watch: {
    status: function () {
      const { Specification } = this.$FeathersVuex.api;
      Specification.find({
        query: { StatusId: this.status.StatusId, Obsolete: false },
      });
    },
  },
  mixins: [makeFindMixin({ service: 'specification' })],
  data() {
    return {
      headers: [
        {
          text: 'Process',
          align: 'start',
          sortable: false,
          value: 'variants',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Description1',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'Description2',
        },
      ],
    };
  },
  computed: {
    specificationParams() {
      return {
        query: {
          StatusId: this.status.StatusId,
          Obsolete: false,
        },
      };
    },
    filteredSpecification() {
      let filtered = this.specification.filter(
        (s) => s.specification_type.Active == true
      );
      if (this.phase != 'All') {
        filtered = filtered.filter((s) => {
          return s.variants
            ? s.variants.some((v) => {
                return v.process.Phase == this.phase;
              })
            : true;
        });
      }
      return filtered.sort((a, b) => {
        return a.Number - b.Number;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
