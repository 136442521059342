import { shallowPopulate } from 'feathers-shallow-populate';
import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '../../feathers-client';

class TestScenarioSteps extends BaseModel {
  constructor(data, options) {
    super(data, options);
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'TestScenarioSteps';
  // Define default properties here
  static instanceDefaults() {
    return {
      Number: 0,
      Description: null,
    };
  }
}
const servicePath = 'test-scenario-steps';
const servicePlugin = makeServicePlugin({
  Model: TestScenarioSteps,
  service: feathersClient.service(servicePath),
  servicePath,
});

const options = {
  include: [
    {
      service: 'process-step',
      nameAs: 'ProcessStep',
      keyHere: 'ProcessStepId',
      keyThere: 'id',
      asArray: false, // by default
      params: {}, // by default
    },
  ],
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [shallowPopulate(options)],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
