<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <FieldEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Name,
                'field',
                currentItem ? 'updated' : 'created'
              );
              const { FieldStep } = $FeathersVuex.api;
              let exists = await FieldStep.find({
                query: {
                  FieldId: saved.id,
                  ProcessStepId: saved.ProcessStepId,
                },
              });
              if (exists.data.length == 0 && saved.ProcessStepId != null) {
                let newRecord = new FieldStep();
                newRecord.ProcessStepId = saved.ProcessStepId;
                newRecord.FieldId = saved.id;
                newRecord.save().then(async (saved2) => {
                  handleSaveResponse(saved2.id, 'field step', 'saved');
                  await refresh();
                });
              }
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then((saved) => handleSaveResponse(saved.Name, 'field', 'removed'))
            .catch((error) => handleErrorResponse(error));
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></FieldEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import FieldEditDialog from '@/components/Fields/FieldEditDialog';

export default {
  name: 'FieldEdit',
  components: {
    FeathersVuexFormWrapper,
    FieldEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    processStep: {
      type: Object,
      required: false,
    },
  },
  computed: {
    item() {
      const { Field } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newField = new Field();
        newField.ProcessStepId = this.processStep ? this.processStep.id : null;
        return newField;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Field, ProcessStep, FieldStep } = this.$FeathersVuex.api;
      await Field.find({
        query: {},
      });
      await ProcessStep.find({
        query: {},
      });
      await FieldStep.find({
        query: {},
      });
    },
  },
  // methods: {
  //   handleSaveResponse(saved) {
  //     this.$toasted.global.success(`Field '${saved.Name}' saved successfully`);
  //     // Redirect to the newly-saved item
  //     // if (this.id === 'new') {
  //     //   this.$router.push({ params: { id: savedTodo._id } });
  //     // }
  //   },
  //   errorHandler(error) {
  //     let errorMessage = '';
  //     error.errors.map((item) => {
  //       errorMessage += item.message + '\n';
  //     });
  //     this.errorMessage = errorMessage;
  //     this.$toasted.global.error(errorMessage);
  //   },
  // },
};
</script>
