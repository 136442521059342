<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px" v-if="currentItem">
      <v-card class="pl-5 pr-5 pb-5">
        <v-card-title class="text-h5">
          Execute Test Run <v-spacer></v-spacer>
          <v-icon class="ml-5" @click="$emit('cancel')" color="primary">
            mdi-close-box
          </v-icon>
        </v-card-title>
        <v-card-subtitle class="text-h5">
          {{ item.TestScenario.Name }}
        </v-card-subtitle>
        <v-card-text>{{ item.TestScenario.Description }}</v-card-text>
        <v-btn @click="validateAll">Validate All</v-btn>
        <v-data-table
          :headers="headers"
          :items="currentItem"
          sort-by="TestScenarioStep.ProcessStep.Number"
          disable-pagination
          hide-default-footer
          show-expand
          class="text-pre-wrap"
        >
          <template v-slot:[`item.TestResult`]="{ item }"
            ><div @click="validateTest(item)">
              <v-icon v-if="item.Statusclosed" color="green" small class="mr-1"
                >mdi-check-bold</v-icon
              ><v-icon v-if="!item.Statusclosed" color="red" small class="mr-1"
                >mdi-close-thick
              </v-icon>
            </div>
          </template>
          <template v-slot:[`item.CreateBug`]="{}"
            ><v-icon color="primary" small class="mr-1"
              >mdi-bug
            </v-icon></template
          >
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              {{ item.TestScenarioStep.ProcessStep.Description }}
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import feathersClient from '@/feathers-client';
import { handleErrorResponse } from '@/utils/MessageHandler';
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentItem: null,
      headers: [
        {
          text: 'Number',
          value: 'TestScenarioStep.ProcessStep.Number',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Name',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Remark',
          value: 'TestScenarioStep.TestRemark',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Expected Result',
          value: 'TestScenarioStep.ExpectedResults',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Result',
          value: 'TestResult',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: 'Create Bug',
          value: 'CreateBug',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        { text: '', value: 'data-table-expand' },
      ],
    };
  },
  methods: {
    async validateAll() {
      this.currentItem = this.currentItem.map((obj) => {
        return { ...obj, Statusclosed: false };
      });

      this.currentItem.forEach(async (obj) => {
        await this.validateTest(obj);
      });
    },
    async validateTest(item) {
      this.currentItem = this.currentItem.map((obj) =>
        obj.id === item.id ? { ...obj, Statusclosed: !obj.Statusclosed } : obj
      );

      feathersClient
        .service('test-scenario-run-steps')
        .patch(item.id, {
          StatusId: item.Statusclosed ? 1 : 6,
        })

        .catch(async (error) => {
          handleErrorResponse(error);
        });
      const { TestScenarioRun, TestScenarioRunSteps, TestScenarioRunDetail } =
        this.$FeathersVuex.api;
      await TestScenarioRunSteps.find({
        query: {},
      });
      await TestScenarioRunDetail.find({
        query: {},
      });
      await TestScenarioRun.find({
        query: {},
      });
    },
  },
  mounted() {
    this.currentItem = this.item.TestScenarioRunSteps.map((m) => {
      return { ...m, Statusclosed: m.Status.Closed };
    });
  },
};
</script>

<style lang="scss" scoped></style>
