<template>
  <div class="ml-5 mr-5">
    <v-data-table
      :headers="headers"
      :items="calculatedFields"
      disable-pagination
      hide-default-footer
      item-key="id"
    >
      <template
        v-if="$can('update', 'test-scenario-steps')"
        v-slot:[`item.Value`]="{ item }"
      >
        <v-select
          class="ma-2 pa-2"
          :items="item.fieldvaluelists"
          item-text="Value"
          item-value="id"
          label="Field Value"
          outlined
          hide-details
          clearable
          dense
          v-model="values[item.id + 1]"
          @blur="updateFieldValueList"
        ></v-select>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  name: 'TestScenarioFieldValues',
  mixins: [makeFindMixin({ service: 'process-step' })],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      importSelected: [],
      values: [],
      headers: [
        {
          text: 'Field',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Value',
          value: 'Value',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  computed: {
    processStepParams() {
      return {
        query: { VariantId: this.item.VariantId, $sort: { Number: 1 } },
      };
    },
    calculatedFields() {
      let fields = [];
      this.processStep.forEach((p) => {
        if (
          p.fields &&
          p.fields.length > 0 &&
          this.item.TestScenarioSteps.some((i) => i.ProcessStepId == p.id)
        ) {
          p.fields.forEach((f) => fields.push(f));
        }
      });
      return fields;
    },
  },
  methods: {
    updateFieldValueList() {
      this.values.forEach((f, i) => {
        if (f || f == null) {
          // console.log(f);
          let curField = this.item.TestScenarioFieldValues.find(
            (fv) => fv.fieldvaluelist.FieldId == i - 1
          );
          if (curField) {
            // console.log('found', curField);
            curField.FieldValueListId = f;
          } else {
            // console.log('not found');
            this.item.TestScenarioFieldValues.push({
              FieldValueListId: f,
              fieldvaluelist: { FieldId: i - 1 },
            });
          }
        }
      });
    },
  },
  mounted() {
    this.item.TestScenarioFieldValues.forEach(
      (f) => (this.values[f.fieldvaluelist.FieldId + 1] = f.FieldValueListId)
    );
  },
};
</script>

<style lang="scss" scoped></style>
