<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      :style="'border-bottom: 5px solid ' + this.customerColor + ' !important;'"
      flat
      dark
      clipped-left
    >
      <v-app-bar-nav-icon @click="drawer()"></v-app-bar-nav-icon>
      <router-link to="/">
        <v-img
          alt="Loxi Logo"
          aspect-ratio="1"
          class="shrink mr-2 pa-0"
          contain
          src="@/assets/Loxi logo_3_white.svg"
          transition="scale-transition"
          height="30"
      /></router-link>
      <span class="ml-4">{{ $appConfig.customer.title }}</span>

      <v-spacer></v-spacer>
      <img
        :alt="this.$appConfig.customer.prefix"
        aspect-ratio="1"
        class="shrink mx-10"
        contain
        id="imgCustomer"
        :src="`logos/${this.$appConfig.customer.logo}`"
        transition="scale-transition"
        height="35"
        @load="imgLoaded()"
      />
      <span v-if="user" class="mr-5"> {{ user.fullname }} </span>
      <v-btn v-if="!isAuthenticated" icon :to="{ name: 'Login' }">
        <v-icon> mdi-account </v-icon></v-btn
      >
      <div v-if="isAuthenticated">
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              v-if="isAuthenticated"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar size="40">
                <img :src="gravatarURL" :alt="user.fullname" /> </v-avatar
            ></v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="$router.push('/Profile')">
              <v-list-item-title> {{ $t('menu.profile') }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="
                $can('create', 'tokens') &&
                $feature('lucidChartIntegration') &&
                tokens
              "
              :href="LucidChartUrl"
            >
              <v-list-item-title>{{ $t('menu.linkLucid') }}</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $store.dispatch('auth/logout');
                $router.push('/Login');
              "
            >
              <v-list-item-title>{{ $t('menu.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-navigation-drawer
        v-if="user && user.role != 'vendor'"
        v-model="$store.state.drawer"
        absolute
        app
        light
        temporary
        width="500"
        clipped
        hoverable
        class="text-body-2"
        ><v-sheet class="pa-4 primary lighten-2">
          <v-text-field
            v-model="search"
            :label="$t('menu.searchTree')"
            dark
            flat
            solo-inverted
            hide-details
            clearable
            @input="handleSearch"
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-treeview
          ref="tree"
          v-if="items"
          :items="items"
          :search="search"
          :filter="filter"
          dense
          hoverable
          @update:active="clickOnNode"
          ><template v-slot:prepend="{ item }">
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="item.Variant == true"
            >
              mdi-format-list-text
            </v-icon>
            <v-icon small class="ma-1" color="primary" v-else>
              mdi-sitemap
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <span @click="clickOnNode(item)"
              >{{ item.Number }} - {{ item.Name }}</span
            ></template
          ></v-treeview
        >
      </v-navigation-drawer>
    </v-app-bar>

    <v-card>
      <v-navigation-drawer
        app
        permanent
        expand-on-hover
        clipped
        mini-variant
        v-if="isAuthenticated"
      >
        <v-list nav dense>
          <v-list-item
            link
            :style="$route.name == 'Process' ? activeStyle : ''"
            @click="$router.push('/Process')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-format-list-bulleted-square</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.processList') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :style="$route.name == 'End2End' ? activeStyle : ''"
            link
            @click="$router.push('/end2end')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-file-tree</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.end2endList') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="$feature('specification') && user.role != 'vendor'"
            :style="$route.name == 'specification' ? activeStyle : ''"
            link
            @click="$router.push('/Specification')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-pin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.specifications')
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="!$feature('specification') && user.role != 'vendor'"
            :style="$route.name == 'requirement' ? activeStyle : ''"
            link
            @click="$router.push('/Requirement')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-pin</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.requirements') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'Fields' ? activeStyle : ''"
            @click="$router.push('/Fields')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-focus-field</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.fields') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="$feature('testScenarios') && user.role != 'vendor'"
            :style="$route.name == 'testscenarios' ? activeStyle : ''"
            link
            @click="$router.push('/testscenarios')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-test-tube</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.testScenarios')
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'Comments' ? activeStyle : ''"
            @click="$router.push('/Comments')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-comment-processing-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.comments') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            :style="$route.name == 'Dashboard' ? activeStyle : ''"
            @click="$router.push('/Dashboard')"
            v-if="user.role != 'vendor'"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-finance</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.dashboard') }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="
              $can('create', 'process') &&
              $can('create', 'variant') &&
              $can('create', 'process-step') &&
              $feature('lucidChartIntegration')
            "
            link
            :style="$route.name == 'DocumentPicker' ? activeStyle : ''"
            @click="$router.push('/documentpicker')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-tray-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{
              $t('menu.processImport')
            }}</v-list-item-title></v-list-item
          >
          <v-list-item
            v-if="
              $can('create', 'process') &&
              $can('create', 'variant') &&
              $can('create', 'process-step') &&
              !$feature('lucidChartIntegration')
            "
            link
            :style="$route.name == 'fileUploader' ? activeStyle : ''"
            @click="$router.push('/fileuploader')"
          >
            <v-list-item-icon>
              <v-icon color="primary">mdi-tray-arrow-up</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.uploadImages') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            v-if="
              (user.role == 'admin' || user.role == 'vendor') &&
              user.vendorId != null
            "
            :style="$route.name == 'VendorResponse' ? activeStyle : ''"
            @click="$router.push('/vendorresponse')"
          >
            <!-- Temporary only allow user ids less than 3-->
            <v-list-item-icon>
              <v-icon color="primary">mdi-forum</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.rfpResponse') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            link
            v-if="user.role == 'admin'"
            :style="$route.name == 'Settings' ? activeStyle : ''"
            @click="$router.push('/settings')"
          >
            <!-- Temporary only allow user ids less than 3-->
            <v-list-item-icon>
              <v-icon color="primary">mdi-cog-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('menu.settings') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-card>
    <v-main><router-view /> </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import { makeFindMixin } from 'feathers-vuex';
import { mapActions } from 'vuex';
import ColorThief from 'colorthief';
import md5 from 'blueimp-md5';

export default {
  name: 'App',
  data() {
    return { customerColor: null, search: null, caseSensitive: false };
  },
  mixins: [
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'tokens' }),
  ],
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),

    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].includes(search)
        : undefined;
    },

    processParams() {
      return {};
    },
    tokensParams() {
      return { query: { Application: 'LucidChart' } };
    },
    activeStyle() {
      return {
        'background-color': '#d1f5ed',
      };
    },
    items() {
      if (this.process.length > 0) {
        return this.process
          .map((i) => {
            return {
              id: i.id,
              processId: i.id,
              Name: i.Name,
              name: i.Name,
              Number: i.Number,
              Variant: false,
              children: i.variants
                ? i.variants
                    .map((i2) => {
                      return {
                        id: i.id + ',' + i2.id,
                        variantId: i2.id,
                        Name: i2.Name,
                        name: i.Number + '.' + i2.Number + ' - ' + i2.Name,
                        Number: i2.Number,
                        Variant: true,
                      };
                    })
                    .sort((a, b) => (a.Number > b.Number ? 1 : -1))
                : null,
            };
          })
          .sort((a, b) => (a.Number > b.Number ? 1 : -1));
      } else return [];
    },
    gravatarURL() {
      let url = null;
      if (this.isAuthenticated) {
        // let hash = CryptoJS.MD5(this.user.email).toString();
        let hash = md5(this.user.email.toLowerCase().trim());
        url = `https://www.gravatar.com/avatar/${hash}.jpg?d=${this.$appConfig.clientBaseUrl}/OfflineFlat.png`;
      } else {
        url = '';
      }
      return url;
    },
    customerBorderStyle() {
      return '3px solid red !important;'; //`3px solid ${this.$appConfig.customer.color} !important;`,
    },
    LucidChartUrl() {
      if (this.tokens[0]) {
        return `https://lucid.app/oauth2/authorizeUser?client_id=${this.tokens[0].ClientId}&redirect_uri=${this.tokens[0].RedirectUri}&scope=lucidchart.document.content+data-service.admin+lucidchart.document.app.picker+offline_access`;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions(['setCurrentProcess']),
    drawer() {
      this.$store.commit('toggleDrawer');
    },
    handleSearch: function (val) {
      if (val) {
        if (!this.allOpened) {
          this.lastOpen = this.open;
          this.allOpened = true;
          this.$refs.tree.updateAll(true);
        }
      } else {
        this.$refs.tree.updateAll(false);
        this.allOpened = false;
        this.open = this.lastOpen;
      }
    },
    clickOnNode(node) {
      let process = this.process.filter((f) => f.id == node.processId)[0];
      this.setCurrentProcess(process);
      node.Variant
        ? this.$router.push({
            name: 'processStep',
            params: { id: node.variantId },
          })
        : this.$router.push({
            name: 'processDetail',
            params: { id: node.processId, number: node.Number },
          });
    },
    imgLoaded() {
      const colorThief = new ColorThief();
      const img = document.getElementById('imgCustomer');

      const rgbToHex = (r, g, b) =>
        '#' +
        [r, g, b]
          .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? '0' + hex : hex;
          })
          .join('');
      let mainColor = null;
      try {
        mainColor = colorThief.getColor(img);
      } catch (error) {
        mainColor = [0, 0, 0];
      }
      let hexColor = rgbToHex(mainColor[0], mainColor[1], mainColor[2]);
      this.customerColor = hexColor;
    },
  },
};
</script>

<style lang="scss">
.toasted.success {
  background-color: #009688 !important;
  font-family: 'Roboto' !important;
  font-weight: bold;
}
.toasted.info {
  background-color: black !important;
  color: white;
  font-family: 'Roboto' !important;
  font-weight: bold;
}

.toasted.error {
  background-color: #f44336 !important;
  font-family: 'Roboto' !important;
  font-weight: bold;
}
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.buttonlink {
  text-decoration: none;
}
.dot {
  height: 25px;
  width: 25px;

  border-radius: 50%;
  display: inline-block;
}
.activeTab {
  // color: #d1f5ed !important;
  background-color: #d1f5ed;
}
</style>
