<template>
  <v-container>
    <ProcessList :end2endId="$route.params.id" />
  </v-container>
</template>

<script>
import ProcessList from '@/components/Process/ProcessList.vue';

export default {
  components: { ProcessList },
};
</script>

<style lang="scss" scoped></style>
