<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px">
      <div v-if="item">
        <v-form ref="form" v-model="validInput">
          <v-card flat class="pb-2">
            <v-card-title>
              <span class="headline">New/Edit Test Scenario</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="1">
                  <v-text-field
                    v-model="item.Number"
                    label="Nr"
                    outlined
                    dense
                    disabled
                    :rules="[rules.number]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="11">
                  <v-text-field
                    v-model="item.Name"
                    label="Name"
                    outlined
                    dense
                    :rules="[rules.required]"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="item.Description"
                    :disabled="!$can('update', item, 'Description')"
                    label="Description"
                    outlined
                    dense
                    rows="1"
                    auto-grow
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="12" md="12">
                  <v-autocomplete
                    v-model="item.VariantId"
                    :items="variantList"
                    item-text="fullname"
                    item-value="id"
                    label="Link to Variant"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-tabs
              v-show="showDetails"
              v-model="selectedTab"
              active-class="activeTab"
            >
              <v-tab>
                <v-icon color="primary" class="mr-2">
                  mdi-arrow-decision
                </v-icon>
                ProcessSteps</v-tab
              >
              <v-tab>
                <v-icon class="mr-2" color="primary">mdi-focus-field</v-icon>
                Fields</v-tab
              >
              <v-tab>
                <v-icon class="mr-2" color="primary">mdi-graph</v-icon>
                Dependent Tests</v-tab
              >
            </v-tabs>
            <v-tabs-items v-show="showDetails" v-model="selectedTab">
              <v-tab-item :eager="true">
                <TestScenarioSteps :item="item" :processStep="filteredSteps" />
              </v-tab-item>
              <v-tab-item :eager="true">
                <TestScenarioFieldValues :item="item" />
              </v-tab-item>
              <v-tab-item :eager="true">
                <TestScenarioDependencies
                  :itemtest="item"
                  :process="process"
                  :tests="tests"
                />
              </v-tab-item>
            </v-tabs-items>

            <v-card-actions>
              <v-btn
                v-if="$can('delete', item)"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn class="ml-2" @click="$emit('remove')" color="error"
                  >Yes</v-btn
                >
              </div>

              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                @click="$emit('save')"
              >
                Save
              </v-btn>

              <v-icon
                v-if="!showDetails"
                :disabled="!validInput"
                large
                @click="showDetails = !showDetails"
                >mdi-chevron-down</v-icon
              >
              <v-icon
                v-if="showDetails"
                :disabled="!validInput"
                large
                @click="showDetails = !showDetails"
                >mdi-chevron-up</v-icon
              >
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import TestScenarioSteps from '@/components/TestScenarios/TestScenarioSteps';
import TestScenarioFieldValues from '@/components/TestScenarios/TestScenarioFieldValues';
import TestScenarioDependencies from '@/components/TestScenarios/TestScenarioDependencies';

import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
export default {
  name: 'TestScenarioEditDialog',
  data() {
    return {
      selectedTab: 0,
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,
      showDetails: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    tests: { type: Array, required: false },
    processStep: { type: Array, required: false },
  },
  mixins: [
    makeFindMixin({ service: 'variant' }),

    makeFindMixin({ service: 'process' }),
  ],
  components: {
    TestScenarioSteps,
    TestScenarioFieldValues,
    TestScenarioDependencies,
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),

    variantParams() {
      return { query: { $sort: { 'process.Number': 1 } } };
    },

    processParams() {
      return {
        query: {
          $sort: { Number: 1 },
        },
      };
    },
    filteredSteps() {
      return this.processStep
        .filter((f) => f.VariantId == this.item.VariantId)
        .sort((a, b) => a.Number - b.Number);
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.process.Number}.${item.Number} ${item.Name}`;
      });
      return list.sort((a, b) => {
        if (a.process.Number === b.process.Number) {
          return a.Number - b.Number;
        }
        return a.process.Number - b.process.Number;
      });
    },
  },
};
</script>
