<template>
  <div v-if="item">
    <v-form ref="form" v-model="validInput">
      <v-card flat>
        <v-card-title>
          <span class="headline">New/Edit Process</span>
        </v-card-title>

        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="item['Number']"
                label="Nr"
                outlined
                dense
                :rules="[rules.required, rules.number]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="item['Name']"
                label="Process Name*"
                :rules="[rules.required]"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="item['DepartmentId']"
                :items="fullDepartment"
                item-text="fullName"
                item-value="id"
                label="Department"
                outlined
                :rules="[rules.required]"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="item['Input']"
                label="Process Input"
                outlined
                dense
                rows="1"
                auto-grow
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="item['Output']"
                label="Process Output"
                outlined
                dense
                rows="1"
                auto-grow
              >
              </v-textarea>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="item['IssueTrackingId']"
                label="Issue Tracking Id"
                outlined
                dense
              ></v-text-field> </v-col
            ><v-col cols="12" md="6">
              <v-text-field
                v-model="item['ExternalDocumentId']"
                label="External Document Id"
                outlined
                dense
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="item['Order']"
                label="Order"
                outlined
                dense
                :rules="[rules.number]"
              >
              </v-text-field> </v-col
            ><v-col cols="12" md="6" v-if="!importProcess">
              <v-text-field
                v-model="item['PageIndexes']"
                label="Page Index"
                outlined
                dense
              >
              </v-text-field
            ></v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="item['Phase']"
                label="Phase"
                outlined
                dense
                :rules="[rules.number]"
              >
              </v-text-field> </v-col
          ></v-row>
          <v-row v-if="importProcess">
            <v-col cols="12" md="4">
              <v-select
                chips
                small-chips
                v-model="item['SelectedPageIndexes']"
                :items="pages"
                item-text="title"
                item-value="index"
                label="Pages"
                outlined
                dense
                multiple
              ></v-select> </v-col
          ></v-row>
        </v-card-text>
        <v-card-actions v-if="!importProcess">
          <v-btn
            v-if="$can('delete', 'process')"
            outlined
            @click="showRemoveMessage = true"
            >Remove</v-btn
          >
          <div v-if="showRemoveMessage" class="ml-5">
            Are you sure?
            <v-btn class="ml-2" @click="$emit('remove')" color="error"
              >Yes</v-btn
            >
          </div>
          <v-spacer></v-spacer>
          <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
          <v-btn :disabled="!validInput" color="primary" @click="$emit('save')">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  name: 'ProcessEditDialog',
  data() {
    return {
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
    };
  },

  computed: {
    departmentParams() {
      return {
        query: {
          $sort: { Name: 1 },
        },
      };
    },
    fullDepartment() {
      this.department.map((item) => {
        item.fullName =
          item.Name +
          ' - ' +
          item.CustomerDepartment.substring(3, 50) +
          ' (' +
          item.ERPModule +
          ')';
      });
      return this.department;
    },
  },
  mixins: [makeFindMixin({ service: 'department' })],
  props: {
    importProcess: { required: false },
    pages: { required: false },
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
