<template>
  <div v-if="variant && process">
    <div class="caption">
      <router-link
        v-if="$store.state.activeEnd2End.Name"
        :to="{
          name: 'Process',
          params: { id: $store.state.activeEnd2End.id },
        }"
        >E2E{{ $store.state.activeEnd2End.Number }} -
        {{ $store.state.activeEnd2End.Name }}</router-link
      ><v-icon v-if="$store.state.activeEnd2End.Name" small
        >mdi-chevron-right</v-icon
      >
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: process.id, number: process.Number },
        }"
        >{{ $appConfig.customer.prefix
        }}{{ process.Number.toString().padStart(4, '0') }} -
        {{ process.Name }}</router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="variant in sortedVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <router-link :to="{ name: 'processStep', params: { id: variant.id } }"
        ><b>{{ variant.Number }} - {{ variant.Name }}</b></router-link
      >
      <v-icon small class="ml-2 mr-2">mdi-forward</v-icon>
      <router-link
        v-if="$feature('specification')"
        :to="{
          name: 'specification',
          params: { id: variant.id, type: 1 },
        }"
        >Specifications ({{
          variant.specifications
            ? variant.specifications.filter(
                (s) =>
                  s.specification_type.Active == true && s.Obsolete == false
              ).length
            : 0
        }})</router-link
      >
      <router-link
        v-else
        :to="{
          name: 'processRequirement',
          params: { id: variant.id, type: 1 },
        }"
        >Requirements ({{
          variant.requirements ? variant.requirements.length : 0
        }})</router-link
      >
      <v-icon v-if="sharePointUrl" small class="ml-2 mr-2">mdi-forward</v-icon>
      <a v-if="sharePointUrl" target="_blank" :href="sharePointUrl"
        >Documents</a
      >
    </div>

    <v-alert border="bottom">
      <div class="subtitle-1">{{ fullProcess }}</div>
    </v-alert>
    <v-row>{{ variant.Input }}</v-row>
    <v-row class="mb-5" v-if="images && images.length > 0">
      <v-carousel
        :height="images.length > 1 ? '600px' : '500px'"
        width="100%"
        :hide-delimiters="images.length > 1 ? false : true"
        :show-arrows="images.length > 1 ? true : false"
      >
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-carousel-item
              class="mb-15"
              v-for="(item, i) in images"
              :key="i"
              :src="item"
              contain
              max-height="500"
              reverse-transition="fade-transition"
              transition="fade-transition"
              @click="openWindow(item)"
            ></v-carousel-item>
          </v-col>
        </v-row>
      </v-carousel>
    </v-row>
    <v-row v-if="embedToken" class="mb-5" justify="center">
      <iframe
        frameBorder="0"
        width="1000px"
        height="500px"
        :src="LucidUrl"
      ></iframe>
    </v-row>
    <v-row class="mb-4"
      ><v-spacer></v-spacer>
      <div v-if="embedToken">
        <v-icon @click="openWindow(LucidUrl)">
          mdi-image-search-outline
        </v-icon>

        <v-icon
          v-if="$can('update', 'process-step')"
          class="ml-5"
          @click="getSteps(variant)"
        >
          mdi-table-sync
        </v-icon>
      </div>
    </v-row>
    <FeathersVuexFind
      v-slot="{ items: steps }"
      service="process-step"
      :params="filter"
      :watch="['params', 'params.query.status', 'params.query.fields']"
      class="mt-3"
    >
      <section>
        <v-progress-linear
          v-if="steps.isFindPending"
          indeterminate
          color="secondary"
        ></v-progress-linear>
        <!-- <draggable v-model="items" v-bind="dragOptions" :move="onMoveCallback"> -->
        <div>
          <v-card v-for="(item, i) in steps" :key="item.id" class="mb-4">
            <v-card-title class="accent" :class="{ obsolete: item.Obsolete }">
              <v-tooltip bottom open-delay="400">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{ item.Number }} - {{ item.Name }}</span
                  >
                </template>
                <span>{{ fullNumber(item) }}</span>
              </v-tooltip>

              <span v-if="item.priority" class="overline ml-4"
                >({{ item.priority.Name }})</span
              >
              <v-icon
                v-if="
                  !item.ExternalId &&
                  $feature('lucidChartIntegration') &&
                  $can('update', 'process-step')
                "
                small
                class="ml-2"
                color="red"
              >
                mdi-link
              </v-icon>
              <v-icon
                v-if="$can('update', subject('process-step', item))"
                small
                class="ml-5"
                @click="clickEdit(item)"
                color="primary"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                class="ml-5"
                @click="clickAddComment(item)"
                color="primary"
              >
                mdi-comment-quote
              </v-icon>
              <v-icon
                v-if="counterCommentsForCustomer(item) > 0"
                small
                class="ml-5"
                @click="clickAddComment(item)"
                color="error"
              >
                mdi-alert
              </v-icon>
              <v-chip
                class="ma-2"
                :color="minCommentStatus(item)"
                :text-color="
                  applyDark(minCommentStatus(item)) ? 'white' : 'black'
                "
                x-small
                v-if="counterCommentsAll(item) > 0"
                >{{ counterComments(item) }}/{{
                  counterCommentsAll(item)
                }}</v-chip
              >
              <v-icon
                v-if="
                  item && item.nbrOfResponses > 0 && $can('read', 'response')
                "
                small
                class="ml-2"
                @click="clickResponses(item)"
                color="primary"
              >
                mdi-format-list-checks
              </v-icon>
              <v-spacer></v-spacer>
              <v-chip
                v-if="item.status && !item.Obsolete"
                class="ma-2"
                :text-color="applyDark(item.status.Color) ? 'white' : 'black'"
                :color="item.status.Color"
                x-small
              >
                {{ item.status.Status }}
              </v-chip>
            </v-card-title>
            <v-card-text>
              <div class="pre" :class="{ obsolete: item.Obsolete }">
                {{ item.Description }}
              </div>
            </v-card-text>

            <v-expand-transition>
              <div v-show="show[i]">
                <v-row>
                  <v-spacer></v-spacer>
                  <Updated
                    class="mr-5"
                    :updateDate="item.updatedAt"
                    :userId="item.ModifiedById"
                    table="process_step"
                    :id="item.id"
                /></v-row>
                <h3 class="ml-2">
                  Fields
                  <v-btn
                    v-if="$can('create', 'field-step')"
                    class="mx-2 ml-10"
                    fab
                    x-small
                    color="primary"
                    @click="clickAddField(item)"
                  >
                    <v-icon dark small> mdi-playlist-plus </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="$can('create', 'field-step')"
                    class="mx-2 ml-10"
                    fab
                    x-small
                    color="primary"
                    @click="clickAddNewField(item)"
                  >
                    <v-icon dark small> mdi-plus </v-icon>
                  </v-btn>
                </h3>
                <v-data-table
                  :headers="headers"
                  :items="item.fields"
                  sort-by="Number"
                  dense
                  disable-pagination
                  hide-default-footer
                  show-expand
                  class="elevation-1"
                >
                  <template v-slot:[`item.unlink`]="{ item }">
                    <v-icon
                      v-if="$can('delete', 'field-step')"
                      small
                      class="mr-2"
                      @click="unlinkField(item)"
                      color="primary"
                    >
                      mdi-link-off
                    </v-icon></template
                  >
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                      <v-card width="auto" class="ma-10">
                        <FieldValueList :fieldId="item.id" />
                      </v-card>
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-expand-transition>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn icon @click="toggle(i)">
                <v-icon v-if="item.fields">{{
                  show[i]
                    ? 'mdi-clipboard-arrow-up-outline'
                    : item.fields.length > 0
                    ? 'mdi-clipboard-arrow-down'
                    : 'mdi-clipboard-arrow-down-outline'
                }}</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </section>
    </FeathersVuexFind>
    <v-row class="mt-3">
      <v-btn
        v-if="$can('create', 'process-step')"
        class="mx-2"
        fab
        small
        color="primary"
        @click="clickAdd()"
      >
        <v-icon dark> mdi-plus </v-icon> </v-btn
      ><v-spacer></v-spacer>

      <v-icon
        v-if="$can('update', 'process-step', 'Number')"
        class="ml-5"
        @click="showReorder = true"
      >
        mdi-order-numeric-ascending
      </v-icon></v-row
    >

    <ProcessStepEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant.id"
    />
    <FieldListAdd
      v-if="detailDialogAddField"
      v-model="detailDialogAddField"
      :stepid="StepId"
    />
    <FieldEdit
      v-if="detailDialogNewField"
      v-model="detailDialogNewField"
      :processStep="currentItem"
    />
    <v-dialog
      v-if="commentDialog"
      v-model="commentDialog"
      width="1000"
      height="auto"
      ><v-card height="50vh" class="pa-5">
        <Comment
          v-if="commentDialog"
          v-model="commentDialog"
          :stepid="StepId"
        />
      </v-card>
    </v-dialog>
    <v-dialog v-model="importSteps" max-width="500px">
      <v-card flat>
        <v-card-title>
          <span class="headline">Import Steps</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers3"
                :items="compareImportedSteps"
                sort-by="Number"
                disable-pagination
                hide-default-footer
                show-select
                :item-class="row_classes"
                v-model="importSelected"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelImport()">Cancel</v-btn>
          <v-btn color="primary" @click="saveImport()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ReOrder v-if="showReorder" v-model="showReorder" :variantId="variant.id" />
    <Response v-model="showResponse" :item="currentItem" />
  </div>
</template>

<script>
import ProcessStepEdit from '@/components/ProcessStep/ProcessStepEdit';
import feathersClient from '@/feathers-client';
import FieldListAdd from '@/components/ProcessStep/FieldListAdd';
import FieldEdit from '@/components/Fields/FieldEdit.vue';
import FieldValueList from '@/components/FieldValues/FieldValueList.vue';
import Comment from '@/components/ProcessStep/Comment';
import ReOrder from '@/components/ProcessStep/ReOrder';
import Updated from '@/components/general/Updated.vue';
import Response from '@/components/ProcessStep/StepResponses';
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import axios from 'axios';
import { subject } from '@casl/ability';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';

async function imageExists(url) {
  var result = false;
  result = await axios
    .head(url)
    .then((res) => res.status == 200)
    .catch(() => false);
  // console.log(url, result);
  return result;
}

export default {
  props: ['variant', 'statusId'],
  components: {
    ProcessStepEdit,
    FieldListAdd,
    FieldValueList,
    FieldEdit,
    Comment,
    ReOrder,
    Updated,
    Response,
  },
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'processId',
      watch: 'processId',
    }),
    makeFindMixin({ service: 'status' }),
    makeFindMixin({
      service: 'tokens',
    }),
  ],
  data() {
    return {
      detailDialog: false,
      commentDialog: false,
      detailDialogAddField: false,
      detailDialogNewField: false,
      detailRemoveField: false,
      showResponse: false,
      currentItem: null,
      FieldId: null,
      StepId: null,
      embedToken: null,
      importSteps: false,
      compareImportedSteps: [],
      importSelected: [],
      items: null,
      showReorder: false,

      images: [],
      show: [],
      list: [],
      headers: [
        {
          text: 'Unlink',
          value: 'unlink',
        },
        {
          text: 'Nr',
          value: 'Number',
        },
        {
          text: 'Name',
          value: 'Name',
        },
        { text: 'Description', value: 'Description' },
        { text: '', value: '' },
        { text: '', value: 'data-table-expand' },
      ],
      headers2: [
        {
          text: 'Code',
          value: 'Code',
        },
        {
          text: 'Value',
          value: 'Value',
        },
        { text: 'Description', value: 'Description' },
      ],
      headers3: [
        {
          text: 'Imported Text',
          value: 'text',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Current Text',
          value: 'loxiText',
          sortable: true,
          groupable: false,
        },
      ],
    };
  },
  watch: {
    variant: async function () {
      if (this.$feature('lucidChartIntegration')) {
        await feathersClient.service('process').get(this.processId);
        await this.LucidEmbed();
      } else {
        await this.getImages();
      }
    },
  },
  computed: {
    processId() {
      return this.variant ? this.variant.ProcessId : null;
    },
    sortedVariants() {
      let variants = [...this.process.variants];
      return variants.sort((a, b) => (a.Number > b.Number ? 1 : -1));
    },
    statusParams() {
      return {};
    },
    tokensParams() {
      return {};
    },
    LucidUrl() {
      return `https://lucid.app/documents/${
        this.process.ExternalDocumentId
      }/viewer?pages=${
        this.variant.PageIndexes
          ? this.variant.PageIndexes
          : this.variant.Number + 1
      }&token=${this.embedToken}`;
    },
    filter() {
      if (this.variant) {
        return {
          query: {
            VariantId: parseInt(this.variant.id),
            $sort: {
              Number: 1,
            },
          },
        };
      } else if (this.statusId) {
        return {
          query: {
            StatusId: parseInt(this.statusId),
            $sort: {
              Number: 1,
            },
          },
        };
      } else {
        return {};
      }
    },
    fullProcess() {
      if (this.variant) {
        return (
          this.$appConfig.customer.prefix +
          this.process.Number.toString().padStart(4, '0') +
          '.' +
          this.variant.Number.toString().padStart(2, '0') +
          ' ' +
          this.variant.Name
        );
      } else {
        return 'Process Steps';
      }
    },
    processUrl() {
      let url = '';
      if (this.variant) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.process.Number.toString() +
          '.' +
          this.variant.Number.toString();

        // '/ATS/ATS' +
        // currentProcess.Number.toString().padStart(4, '0') +
        // '.' +
        // currentVariant.Number.toString().padStart(2, '0');
      }
      return url;
    },
    processUrlFull() {
      let url = '';
      if (this.variant) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.$appConfig.customer.prefix +
          this.process.Number.toString().padStart(4, '0') +
          '.' +
          this.variant.Number.toString().padStart(2, '0');
      }
      return url;
    },
    sharePointUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'SharePoint');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${
            this.$appConfig.customer.prefix
          }${this.process.Number.toString().padStart(4, '0')}`;
        }
      }
      return '';
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  methods: {
    subject,

    toggle(i) {
      if (this.show[i] == undefined) {
        this.show[i] = true;
        this.show = [...this.show];
      } else {
        this.show[i] = !this.show[i];
        this.show = [...this.show];
      }
    },
    row_classes(item) {
      if (item.text != item.loxiText) {
        return 'highlight';
      }
    },
    applyDark(item) {
      if (item) {
        var color = item.charAt(0) === '#' ? item.substring(1, 7) : item;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
        return L > 0.179 ? false : true;
      } else {
        return false;
      }
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    fullNumber(item) {
      return `${
        this.$appConfig.customer.prefix
      }${this.process.Number.toString().padStart(
        4,
        '0'
      )}.${this.variant.Number.toString().padStart(
        2,
        '0'
      )}.${item.Number.toString().padStart(2, '0')}`;
    },
    counterComments(items) {
      return items.comments
        ? items.comments.filter((x) => x.StatusId < 6).length
        : 0;
    },
    counterCommentsAll(items) {
      return items.comments ? items.comments.length : 0;
    },
    counterCommentsForCustomer(items) {
      return items.comments
        ? items.comments.filter(
            (x) => (x.StatusId < 6) & (x.ForCustomer == true)
          ).length
        : 0;
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickEditFieldList(item) {
      this.currentItemFieldList = item;
      this.detailDialogFieldList = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    clickAddNewField(item) {
      this.currentItem = item;
      this.detailDialogNewField = true;
    },
    clickRemoveField(item) {
      this.detailRemoveField = true;
      this.StepId = item.field_step.ProcessStepId;
      this.FieldId = item.field_step.FieldId;
    },
    clickAddField(item) {
      this.detailDialogAddField = true;
      this.StepId = item.id;
    },
    clickAddComment(item) {
      this.commentDialog = true;
      this.StepId = item.id;
      // console.log(item.id);
    },
    async getImages() {
      this.images = [];

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${this.processUrl}${
          i == 0 ? '' : '-' + i.toString()
        }.svg`;
        // console.log(imageCheck);
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }
      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${this.processUrlFull}${
          i == 0 ? '' : '-' + i.toString()
        }.svg`;
        // console.log(imageCheck);
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }
    },
    async LucidEmbed() {
      if (this.process && this.process.ExternalDocumentId) {
        if (!this.process.EmbedId) {
          await getDocumentEmbed(this.process);
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken(this.process);
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
    async getSteps(variant) {
      let result = await getDocumentInfo(this.process);

      let steps = this.variant.process_steps.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.ExternalId = item.ExternalId;
        newitem.text = item.Name;
        return newitem;
      });

      result =
        parseDocumentInfo(result)[
          variant.PageIndexes
            ? variant.PageIndexes.split(',')[0] - 1
            : variant.Number
        ];

      this.compareImportedSteps = result.blocks.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.text = item.text;
        let loxi = steps.filter((item2) => item2.ExternalId == item.id);

        if (loxi.length == 1) {
          newitem.loxiId = loxi[0].id;
          newitem.loxiText = loxi[0].text;
        }

        return newitem;
      });

      this.importSteps = true;
    },
    cancelImport() {
      this.importSteps = false;
    },
    async saveImport() {
      if (this.importSelected.length > 0) {
        this.importSelected.forEach(async (item, index) => {
          // console.log(item.id);
          const { ProcessStep } = this.$FeathersVuex.api;
          if (!item.loxiId) {
            let newStep = new ProcessStep();
            newStep.Number = index + 1;
            newStep.ExternalId = item.id;
            newStep.ApplicationId = 1;
            newStep.Name = item.text;
            newStep.VariantId = this.variant.id;
            newStep.StatusId = 1;
            newStep.PriorityId = 1;
            await newStep.save();
          } else {
            let newStep = new ProcessStep();
            newStep.Name = item.text;
            // console.log(item.loxiId, newStep);
            await feathersClient.service('process-step').patch(item.loxiId, {
              Name: item.text,
            });
          }
          //await Process.find({ query: { id: this.process.id } });
        });
      }
      this.importSteps = false;
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    minCommentStatus(item) {
      const total = Object.values(item.comments).map(({ status }) => status.id);
      const statusMin = this.status.filter((i) => i.id == Math.min(...total));
      return statusMin.length > 0 ? statusMin[0].Color : 'primary';
    },
    async unlinkField(item) {
      const { ProcessStep } = this.$FeathersVuex.api;

      if (item.field_step.id) {
        await this.$store.dispatch('field-step/remove', item.field_step.id);
        await ProcessStep.find({ query: {} });
      }
    },
  },
  async mounted() {
    // this.list = this.variant.process_steps.clone();
    if (this.$feature('lucidChartIntegration')) {
      await this.LucidEmbed();
    } else {
      await this.getImages();
    }
  },
};
</script>
<style lang="scss">
.obsolete {
  text-decoration: line-through;
}

.pre {
  white-space: pre-line;
  word-wrap: break-word;
  font-family: inherit;
}
.highlight {
  background-color: #ef9a9a;
}
</style>
