<template>
  <div class="ma-5">
    <v-row
      ><v-col>
        <v-toolbar-title>Select Dependent tests</v-toolbar-title>
        <v-treeview dense hoverable :items="treeViewItems"
          ><template v-slot:prepend="{ item }">
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="
                item.Type == 'variant' &&
                !activeParentTests.some((s) => s.variant.id == item.id)
              "
            >
              mdi-format-list-text
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="red"
              v-if="
                item.Type == 'variant' &&
                activeParentTests.some((s) => s.variant.id == item.id)
              "
            >
              mdi-format-list-text
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="red"
              v-if="
                item.Type == 'process' &&
                activeParentTests.some((s) => s.variant.process.id == item.id)
              "
            >
              mdi-sitemap
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="
                item.Type == 'process' &&
                !activeParentTests.some((s) => s.variant.process.id == item.id)
              "
            >
              mdi-sitemap
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="
                item.Type == 'test' &&
                itemtest.TestScenarioPreTests.find(
                  (f) => f.ParentTestScenarioId == item.id
                ) == undefined
              "
            >
              mdi-test-tube
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="red"
              v-if="
                item.Type == 'test' &&
                itemtest.TestScenarioPreTests.find(
                  (f) => f.ParentTestScenarioId == item.id
                ) != undefined
              "
            >
              mdi-test-tube
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <span>{{ item.Number }} - {{ item.Name }}</span></template
          >
          <template v-slot:append="{ item }">
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="
                item.Type == 'test' &&
                itemtest.TestScenarioPreTests.find(
                  (f) => f.ParentTestScenarioId == item.id
                ) == undefined
              "
              @click="clickOnNode(item)"
            >
              mdi-plus
            </v-icon>
            <v-icon
              small
              class="ma-1"
              color="primary"
              v-if="
                item.Type == 'test' &&
                itemtest.TestScenarioPreTests.find(
                  (f) => f.ParentTestScenarioId == item.id
                ) != undefined
              "
              @click="clickOnNode(item)"
            >
              mdi-minus
            </v-icon></template
          ></v-treeview
        ></v-col
      ><v-col>
        <v-toolbar-title>Dependent tests</v-toolbar-title>

        <v-list dense>
          <div v-for="test in this.activeParentTests" :key="test.id">
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ test.Name }}</v-list-item-title>
                <v-list-item-subtitle class="caption"
                  >{{ test.variant.process.Name }} >
                  {{ test.variant.Name }}</v-list-item-subtitle
                >
                {{ test.Description }}
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
      </v-col></v-row
    >
  </div>
</template>

<script>
export default {
  props: {
    itemtest: {
      type: Object,
      required: true,
    },
    process: {
      type: Array,
      required: true,
    },
    tests: {
      type: Array,
      required: true,
    },
  },
  computed: {
    activeParentTests() {
      return this.itemtest.TestScenarioPreTests
        ? this.itemtest.TestScenarioPreTests.map((p) =>
            this.tests.find((f) => f.id == p.ParentTestScenarioId)
          )
        : [];
    },
    treeViewItems() {
      if (this.process) {
        return this.process
          .filter((f) =>
            this.tests
              .filter((f) => f.id != this.itemtest.id)
              .map((m) => m.variant.process)
              .map((m) => m.id)
              .includes(f.id)
          ) // only show processes with tests (first filter out the current test)
          .map((i) => {
            return {
              id: i.id,
              processId: i.id,
              Name: i.Name,
              name: i.Name,
              Number: i.Number,
              Type: 'process',
              children: i.variants
                ? i.variants
                    .map((i2) => {
                      return {
                        id: i2.id,
                        fullId: i.id + ',' + i2.id,
                        variantId: i2.id,
                        Name: i2.Name,
                        name: i.Number + '.' + i2.Number + ' - ' + i2.Name,
                        Number: i2.Number,
                        Type: 'variant',
                        children: this.tests
                          .filter(
                            (f) =>
                              f.VariantId == i2.id &&
                              f.id != this.itemtest.id && // Do not show own test
                              f.ValidInput
                          )
                          .map((i3) => {
                            return {
                              id: i3.id,
                              fullId: i.id + ',' + i2.id + ',' + i3.id,
                              Type: 'test',
                              name: i3.Name,
                              Name: i3.Name,
                              Number: i3.Number,
                            };
                          }),
                      };
                    })
                    .sort((a, b) => (a.Number > b.Number ? 1 : -1))
                : null,
            };
          })
          .sort((a, b) => (a.Number > b.Number ? 1 : -1));
      } else return [];
    },
  },
  methods: {
    clickOnNode(item) {
      if (
        this.itemtest.TestScenarioPreTests.find(
          (f) => f.ParentTestScenarioId == item.id
        ) == undefined
      ) {
        this.itemtest.TestScenarioPreTests.push({
          TestScenarioId: this.itemtest.id,
          ParentTestScenarioId: item.id,
        });
      } else {
        // remove
        this.itemtest.TestScenarioPreTests =
          this.itemtest.TestScenarioPreTests.filter(
            (f) => f.ParentTestScenarioId != item.id
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
