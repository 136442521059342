<template>
  <div>
    <span v-if="$route.name == 'Comments'">
      <b>Comments Summary</b>
      <v-row align="stretch" justify="center">
        <v-col cols="10">
          <v-card flat v-if="statusList && status">
            <v-card-title>
              <span v-for="stat in statusList" :key="stat.id">
                <v-chip
                  small
                  class="ma-2"
                  :color="getStatusInfo(stat.StatusId).Color"
                  :text-color="
                    applyDark(getStatusInfo(stat.StatusId).Color)
                      ? 'white'
                      : 'black'
                  "
                >
                  <b class="mr-3"> {{ stat.count }}</b>
                  {{ getStatusInfo(stat.StatusId).Status }}
                  <b class="mr-3 ml-2">
                    {{
                      ' -  ' +
                      Math.round((stat.count / totalStatus) * 100) +
                      '%'
                    }}</b
                  >
                </v-chip>
              </span>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="2">
          <v-card flat height="100%">
            <v-card-title
              v-if="weighedProgress && !isNaN(weighedProgress)"
              class="justify-center"
            >
              <v-progress-circular
                :rotate="-90"
                :size="75"
                :width="7"
                :value="weighedProgress"
                color="black"
              >
                <span class="body-1">{{ weighedProgress }}%</span>
              </v-progress-circular>
            </v-card-title>
            <v-card-title v-else class="justify-center"
              ><span class="body-1"> --.--%</span>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </span>

    <v-card flat>
      <v-card-title v-if="$route.name != 'Comments'">
        <span class="headline">Comments</span><v-spacer></v-spacer>
        <v-icon class="ml-5" @click="showDialog = false" color="primary">
          mdi-close-box
        </v-icon>
      </v-card-title>

      <v-card-text class="mt-5">
        <v-row
          ><v-checkbox
            v-if="!stepid"
            v-model="showAll"
            label="Show All"
          ></v-checkbox
          ><v-spacer></v-spacer>
          <v-icon
            v-if="$can('create', 'comment')"
            class="ml-5"
            @click="clickEdit(null)"
            color="primary"
            large
          >
            mdi-plus-circle
          </v-icon></v-row
        ></v-card-text
      >

      <v-data-table
        :headers="headers"
        :items="filteredComments"
        :items-per-page="15"
        :sort-by="['Status', 'createdAt']"
        :sort-desc="[true, true]"
        item-key="id"
        class="elevation-1"
        show-expand
        :hide-default-footer="$route.name == 'Comments' ? false : true"
        :disable-pagination="$route.name == 'Comments' ? false : true"
      >
        <template v-slot:[`item.Comment`]="{ item }">
          {{ item.Comment }}
          <span v-if="item.comment_replies">
            <v-icon
              small
              class="mr-2"
              v-if="item.comment_replies.length > 0"
              color="primary"
            >
              mdi-reply
            </v-icon>
          </span>
        </template>
        <template v-slot:[`item.process_step`]="{ item }"
          ><span class="caption" v-if="item.process_step"
            >{{ item.process_step.variant.process.Name }}/
            <a
              :href="'/#/processStep/' + item.process_step.variant.id"
              v-if="item.process_step"
              >{{ item.process_step.variant.Name }}</a
            >({{ item.process_step.Number }})</span
          >
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon
            v-if="$can('update', subject('comment', item))"
            small
            class="mr-2"
            @click="clickEdit(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
        <template v-slot:[`item.ForCustomer`]="{ item }">
          <v-icon small class="mr-2" v-if="item.ForCustomer" color="error">
            mdi-alert
          </v-icon>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            v-if="item.status"
            :text-color="applyDark(item.status.Color) ? 'white' : 'black'"
            class="ma-2"
            :color="item.status.Color"
            x-small
          >
            {{ item.status.Status }}
          </v-chip>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-row
              ><v-col
                cols="8"
                md="8"
                v-if="item.comment_replies && item.comment_replies.length > 0"
              >
                <v-card
                  width="100%"
                  v-for="reply in item.comment_replies
                    .slice()
                    .sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))"
                  :key="reply.id"
                  class="ma-2"
                  outlined
                >
                  <v-card-title class="caption"
                    ><v-chip
                      small
                      :color="reply.CreatedBy.color"
                      :text-color="
                        applyDark(reply.CreatedBy.color) ? 'white' : 'black'
                      "
                      ><b class="mr-1">{{ reply.CreatedBy.fullname }}</b> -
                      {{ new Date(reply.createdAt).toLocaleString() }}</v-chip
                    ><v-icon
                      v-if="reply.Like"
                      class="ml-1"
                      @click="toggleLike(reply)"
                      color="primary"
                      small
                    >
                      mdi-thumb-up </v-icon
                    ><v-icon
                      v-else
                      class="ml-1"
                      @click="toggleLike(reply)"
                      color="primary"
                      small
                    >
                      mdi-thumb-up-outline </v-icon
                    ><v-spacer></v-spacer
                    ><v-icon
                      v-if="checkEditReply(reply)"
                      small
                      class="ml-5"
                      @click="clickEditReply(reply)"
                      color="primary"
                    >
                      mdi-pencil
                    </v-icon></v-card-title
                  ><v-card-text>
                    {{ reply.CommentReply }}
                  </v-card-text>
                </v-card></v-col
              ></v-row
            ><v-row
              ><v-col cols="8" md="8" class="text-right"
                ><v-icon
                  v-if="$can('create', 'comment-reply')"
                  @click="clickAddReply(item)"
                  color="primary"
                  large
                >
                  mdi-reply-circle
                </v-icon></v-col
              >
            </v-row>
          </td></template
        >
      </v-data-table>
    </v-card>

    <ProcessCommentEdit
      v-model="showAdd"
      :currentItem="currentItem"
      :stepid="stepid"
    />
    <v-dialog v-model="addReply" max-width="800px">
      <v-form ref="form">
        <v-card flat>
          <v-card-title>
            <span class="headline">Add Reply</span>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea
                  v-model="reply"
                  label="Comment Reply"
                  outlined
                  dense
                  rows="1"
                  auto-grow
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn outlined @click="cancelReply()">Cancel</v-btn>
            <v-btn color="primary" @click="saveReply()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { subject } from '@casl/ability';
import { makeFindMixin } from 'feathers-vuex/dist';

import ProcessCommentEdit from '@/components/ProcessStep/ProcessCommentEdit';

export default {
  data() {
    return {
      showAdd: false,
      currentItem: null,
      showAll: this.$route.name == 'Comments' ? false : true,
      addReply: false,
      reply: '',
      commentId: null,
      replyId: null,
    };
  },
  components: { ProcessCommentEdit },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'comment' }),
  ],
  props: {
    value: { type: Boolean },
    stepid: {},
  },
  methods: {
    subject,
    clickEdit(item) {
      this.currentItem = item;
      this.showAdd = true;
    },
    checkEditReply(item) {
      return this.$can('update', subject('comment-reply', item));
    },
    async toggleLike(reply) {
      let baseurl = this.$appConfig.apiBaseUrl;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
        },
      };

      let body = {
        Like: !reply.Like,
      };

      let url = baseurl + '/comment-reply/' + reply.id;
      await axios.patch(url, body, config);
      const { Comment } = this.$FeathersVuex.api;
      await Comment.find({ query: {} });
      //console.log(res);
    },
    getStatusInfo(statusId) {
      let filtered = this.status.filter((item) => {
        return item.id == statusId;
      });
      return filtered[0];
    },
    applyDark(item) {
      if (item) {
        var color = item.charAt(0) === '#' ? item.substring(1, 7) : item;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        var uicolors = [r / 255, g / 255, b / 255];
        var c = uicolors.map((col) => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
        return L > 0.179 ? false : true;
      } else {
        return false;
      }
    },
    clickAddReply(item) {
      this.addReply = true;
      this.commentId = item.id;
      this.replyId = null;
      this.reply = '';
      this.mode = 'create';
    },
    clickEditReply(reply) {
      this.addReply = true;
      this.replyId = reply.id;
      this.commentId = null;
      this.reply = reply.CommentReply;
      this.mode = 'edit';
    },
    cancelReply() {
      this.addReply = false;
      this.reply = '';
    },
    async saveReply() {
      let baseurl = this.$appConfig.apiBaseUrl;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
        },
      };

      let body = null;

      let url = baseurl + '/comment-reply';
      if (this.commentId) {
        body = {
          CommentReply: this.reply,
          CommentId: this.commentId,
        };
        await axios.post(url, body, config);
      } else if (this.replyId) {
        url = `${url}/${this.replyId}`;
        body = {
          CommentReply: this.reply,
        };
        await axios.patch(url, body, config);
      }
      const { Comment } = this.$FeathersVuex.api;
      await Comment.find({ query: {} });
      this.addReply = false;
      this.reply = '';
      //console.log(res);
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    statusParams() {
      return {};
    },
    commentParams() {
      return this.filter;
    },
    filteredComments() {
      if (this.comment) {
        return this.showAll
          ? this.comment
          : this.comment.filter((i) =>
              i.status ? i.status.Closed === false : true
            );
      } else return [];
    },
    statusList() {
      if (this.comment && this.status) {
        let statusList = [];

        // Get sum of all statusses for the steps
        const stepsStatus = Array.from(
          this.comment.reduce(
            (m, { StatusId }) => m.set(StatusId, (m.get(StatusId) || 0) + 1),
            new Map()
          ),
          ([StatusId, count]) => ({ StatusId, count })
        );
        if (Object.keys(stepsStatus).length > 0) statusList.push(stepsStatus);

        // Make the sum per status
        const res = Array.from(
          statusList
            .flat()
            .reduce(
              (m, { StatusId, count }) =>
                m.set(StatusId, (m.get(StatusId) || 0) + count),
              new Map()
            ),
          ([StatusId, count]) => ({ StatusId, count })
        );
        // console.log('b', res);

        if (res) {
          res.map((item) => {
            let filtered = this.status.filter((s) => {
              return s.id == item.StatusId;
            });
            if (filtered.length > 0) item.Number = filtered[0].Number;
            else item.Number = 0;
          });
          return res.sort((a, b) => a.Number - b.Number);
        } else return [];
      } else return [];
    },
    totalStatus() {
      let total = 0;
      if (this.statusList) {
        total = this.statusList.reduce((sum, a) => {
          return sum + a.count;
        }, 0);
      }
      return total;
    },
    weighedProgress() {
      if (this.status && this.statusList) {
        let sum = 0;
        let total = 0;
        // let statusList = this.filteredStatus(id).filter(
        //   (item) => item.StatusId != 7
        // );
        const excludeStatusses = this.status
          ? this.status.filter((s) => s.Progress == 0 && s.Closed == true)
          : []; // Get statusses to filter out

        //Filter out 'not in scope'
        const filteredList = this.statusList.filter(
          (item) => !excludeStatusses.some((f) => f.id == item.StatusId)
        );

        filteredList.map((item) => {
          sum +=
            item.count *
            this.status.filter((sl) => item.StatusId == sl.id)[0].Progress;
          total += item.count;
        });
        let result = (sum / total) * 100;
        return result == 100 ? result.toFixed(0) : result.toFixed(2);
      } else return 0;
    },
    filter() {
      if (this.stepid) {
        return {
          query: {
            ProcessStepId: this.stepid,
          },
        };
      } else {
        return {
          // query: { StatusId: { $lt: this.showAll ? 99 : 6 } },
          query: {},
        };
      }
    },
    headers() {
      return [
        {
          text: 'Process',
          align: 'start',
          sortable: true,
          value: 'process_step',
        },
        {
          text: 'Comment',
          align: 'start',
          sortable: true,
          value: 'Comment',
        },
        {
          text: 'CreatedAt',
          align: 'start',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: 'Created By',
          align: 'start',
          sortable: true,
          value: 'CreatedBy.fullname',
        },
        {
          text: 'For Customer',
          value: 'ForCustomer',
          align: 'center',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        { text: '', value: 'data-table-expand' },
      ];
    },
  },
};
</script>

<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
