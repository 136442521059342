const config = {
  apiBaseUrl: parse(process.env.VUE_APP_API_BASE_URL, 'http://localhost:3030'),
  clientBaseUrl: parse(
    process.env.VUE_APP_CLIENT_BASE_URL,
    'http://localhost:8080'
  ),
  customer: {
    title: parse(process.env.VUE_APP_CUSTOMER_TITLE, 'Loxi Dev'),
    logo: parse(process.env.VUE_APP_CUSTOMER_LOGO, 'LamboWhite.png'),
    logoLarge: parse(process.env.VUE_APP_CUSTOMER_LOGOLARGE, 'LamboDark.png'),
    prefix: parse(process.env.VUE_APP_CUSTOMER_PREFIX, 'DEV'),
    home: parse(process.env.VUE_APP_CUSTOMER_HOME, 'process'),
    imageType: parse(process.env.VUE_APP_CUSTOMER_IMAGETYPE, 'lucid'), // svg, png, lucid
    imagePrefix: parse(process.env.VUE_APP_CUSTOMER_IMAGEPREFIX, true), // is image prefixed by the customerprefix
  },
  externalIssues: parse(process.env.VUE_APP_EXTERNAL_ISSUES, ''),
  locale: parse(process.env.VUE_APP_LOCALE, 'en'),
  version: '2.5.1',

  features: {
    specification: parse(process.env.VUE_APP_FEATURE_SPECIFICATION, true),
    erpApplication: parse(process.env.VUE_APP_ERP_APPLICATION, true),
    lucidChartIntegration: parse(
      process.env.VUE_APP_FEATURE_LUCIDCHARTINTEGRATION,
      false
    ),
    testScenarios: parse(process.env.VUE_APP_TEST_SCENARIOS, true),
  },
};

function feature(name) {
  return config.features[name];
}
function parse(value, fallback) {
  if (typeof value === 'undefined') {
    return fallback;
  }
  switch (typeof fallback) {
    case 'boolean':
      return !!JSON.parse(value);
    case 'number':
      return JSON.parse(value);
    default:
      return value;
  }
}
export { config };

export default {
  install(Vue) {
    Vue.appConfig = config;
    Vue.feature = feature;
    Vue.prototype.$appConfig = config;
    Vue.prototype.$feature = feature;
  },
};
