<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <TestScenarioEditDialog
        :item="clone"
        :tests="tests"
        :processStep="processStep"
        @save="
          showDialog = false;
          saveItem(save, clone);
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Description, 'test scenario', 'removed');

              await refresh();
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            });

          showDialog = false;
        "
        @cancel="showDialog = false"
      ></TestScenarioEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import TestScenarioEditDialog from '@/components/TestScenarios/TestScenarioEditDialog';

export default {
  name: 'TestScenarioEdit',
  components: {
    FeathersVuexFormWrapper,
    TestScenarioEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    variant: {},
    tests: { type: Array, required: false },
    processStep: { type: Array, required: false },
  },
  computed: {
    item() {
      const { TestScenario } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newTestScenario = new TestScenario();
        newTestScenario.VariantId = this.variant ? this.variant.id : null;
        newTestScenario.TestScenarioSteps = [];
        newTestScenario.TestScenarioFieldValues = [];
        newTestScenario.TestScenarioPreTests = [];

        return newTestScenario;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async saveItem(save, item) {
      save()
        .then(async (saved) => {
          handleSaveResponse(
            saved.Name,
            'test scenario',
            this.currentItem ? 'updated' : 'created'
          );
          await this.refresh();

          //save steps
          const { TestScenarioSteps } = this.$FeathersVuex.api;
          if (item.TestScenarioSteps && item.TestScenarioSteps.length > 0) {
            item.TestScenarioSteps.forEach(async (t) => {
              let TestStep = await TestScenarioSteps.find({
                query: {
                  TestScenarioId: item.id,
                  ProcessStepId: t.ProcessStepId,
                },
              });
              if (TestStep.total == 1) {
                // update
                feathersClient
                  .service('test-scenario-steps')
                  .patch(TestStep.data[0].id, {
                    ExpectedResults: t.ExpectedResults,
                    TestRemark: t.TestRemark,
                  })
                  .catch(async (error) => {
                    handleErrorResponse(error);
                  });
              } else if (TestStep.total == 0) {
                feathersClient
                  .service('test-scenario-steps')
                  .create({
                    TestScenarioId: item.id,
                    ProcessStepId: t.ProcessStepId,
                    ExpectedResults: t.ExpectedResults,
                    TestRemark: t.TestRemark,
                  })
                  .catch(async (error) => {
                    handleErrorResponse(error);
                  });
              }
            });
          }
          let RemoveSteps = await TestScenarioSteps.find({
            query: {
              TestScenarioId: item.id,
            },
          });
          RemoveSteps = RemoveSteps.data.filter(
            (x) =>
              !item.TestScenarioSteps.filter(
                (y) => y.ProcessStepId === x.ProcessStepId
              ).length
          );
          if (RemoveSteps) {
            RemoveSteps.forEach((r) => {
              feathersClient
                .service('test-scenario-steps')
                .remove(r.id)
                .catch(async (error) => {
                  handleErrorResponse(error);
                });
            });
          }

          // save values
          if (
            item.TestScenarioFieldValues &&
            item.TestScenarioFieldValues.length > 0
          ) {
            const { TestScenarioFieldValues } = this.$FeathersVuex.api;
            item.TestScenarioFieldValues.forEach(async (t) => {
              let TestValues = await TestScenarioFieldValues.find({
                query: {
                  TestScenarioId: item.id,
                },
              });
              TestValues = TestValues.data.filter(
                (f) => f.fieldvaluelist.FieldId == t.fieldvaluelist.FieldId
              );
              if (TestValues.length == 1) {
                if (t.FieldValueListId != null) {
                  // update
                  feathersClient
                    .service('test-scenario-field-values')
                    .patch(TestValues[0].id, {
                      FieldValueListId: t.FieldValueListId,
                    })
                    .catch(async (error) => {
                      handleErrorResponse(error);
                    });
                } else {
                  //delete
                  feathersClient
                    .service('test-scenario-field-values')
                    .remove(TestValues[0].id)
                    .catch(async (error) => {
                      handleErrorResponse(error);
                    });
                }
              } else if (TestValues.length == 0) {
                feathersClient
                  .service('test-scenario-field-values')
                  .create({
                    TestScenarioId: item.id,
                    FieldValueListId: t.FieldValueListId,
                  })
                  .catch(async (error) => {
                    handleErrorResponse(error);
                  });
              }
            });
          }

          // save Dependent Tests
          const { TestScenarioPreTest } = this.$FeathersVuex.api;
          if (
            item.TestScenarioPreTests &&
            item.TestScenarioPreTests.length > 0
          ) {
            item.TestScenarioPreTests.forEach(async (t) => {
              let PreTest = await TestScenarioPreTest.find({
                query: {
                  TestScenarioId: item.id,
                  ParentTestScenarioId: t.ParentTestScenarioId,
                },
              });
              if (PreTest.total == 0) {
                // create
                feathersClient
                  .service('test-scenario-pretest')
                  .create({
                    TestScenarioId: item.id,
                    ParentTestScenarioId: t.ParentTestScenarioId,
                  })
                  .catch(async (error) => {
                    handleErrorResponse(error);
                  });
              } else if (PreTest.total == 1) {
                // do nothing alread exists
              }
            });
          }
          // Remove dependent tests
          let RemoveTests = await TestScenarioPreTest.find({
            query: {
              TestScenarioId: item.id,
            },
          });
          RemoveTests = RemoveTests.data.filter(
            (x) =>
              !item.TestScenarioPreTests.filter(
                (y) => y.ParentTestScenarioId === x.ParentTestScenarioId
              ).length
          );
          // console.log(RemoveTests);
          if (RemoveTests) {
            RemoveTests.forEach((r) => {
              feathersClient
                .service('test-scenario-pretest')
                .remove(r.id)
                .catch(async (error) => {
                  handleErrorResponse(error);
                });
            });
          }

          // Final Refresh
          await this.refresh();
        })
        .catch(async (error) => {
          handleErrorResponse(error);
          await this.refresh();
        });
    },
    async refresh() {
      const { TestScenario, TestScenarioFieldValues } = this.$FeathersVuex.api;
      await TestScenarioFieldValues.find({
        query: {},
      });
      await TestScenario.find({
        query: {},
      });
    },
  },
};
</script>
