<template>
  <v-card width="100vw" flat>
    <v-data-table
      :headers="headers"
      :items="filteredProcessStep"
      :items-per-page="-1"
      item-key="id"
      :loading="isFindProcessStepPending"
      class="elevation-1"
    >
      <template v-slot:[`item.variant`]="{ item }">
        <span class="caption" v-if="item.variant"
          >{{ item.variant.process.Number }}.{{ item.variant.process.Name }}/
          <a :href="'/#/processStep/' + item.variant.id" v-if="item.variant">{{
            item.variant.Name
          }}</a></span
        >
      </template>
      <template v-slot:[`item.Name`]="{ item }">
        <span>{{ item.Number }}. {{ item.Name }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
export default {
  props: ['status', 'phase'],
  watch: {
    status: function () {
      const { ProcessStep } = this.$FeathersVuex.api;
      ProcessStep.find({
        query: { StatusId: this.status.StatusId, Obsolete: false },
      });
    },
  },
  mixins: [makeFindMixin({ service: 'process-step' })],
  data() {
    return {
      headers: [
        {
          text: 'Process',
          align: 'start',
          sortable: false,
          value: 'variant',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Name',
        },
        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'Description',
        },
      ],
    };
  },
  computed: {
    processStepParams() {
      return { query: { StatusId: this.status.StatusId, Obsolete: false } };
    },
    filteredProcessStep() {
      let filtered = [];
      if (this.phase == 'All') {
        filtered = this.processStep;
      } else {
        filtered = this.processStep.filter(
          (ps) => ps.variant.process.Phase == this.phase
        );
      }
      return filtered.sort((a, b) => {
        return (
          a.variant.process.Number - b.variant.process.Number ||
          a.variant.Number - b.variant.Number ||
          a.Number - b.Number
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
