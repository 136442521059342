<template>
  <div>
    <v-progress-linear
      indeterminate
      height="3"
      color="secondary"
      v-if="!retrievedVariant"
    ></v-progress-linear>
    <v-card v-if="this.variant.id && retrievedVariant">
      <v-card-title class="overline" color="indigo darken-4"
        >{{ retrievedVariant.Number }}. {{ retrievedVariant.Name }} (
        <span v-if="retrievedVariant.application">{{
          retrievedVariant.application.Name
        }}</span>

        )

        <v-icon
          v-if="
            !retrievedVariant.ExternalId &&
            $feature('lucidChartIntegration') &&
            $can('update', 'variant')
          "
          small
          class="ml-2"
          color="red"
        >
          mdi-link
        </v-icon>
        <v-spacer />
        <v-icon
          v-if="checkEditVariant(retrievedVariant)"
          small
          class="mr-2"
          @click="clickEdit(retrievedVariant)"
          color="primary"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$can('update', 'process-step')"
          small
          class="mr-2"
          @click="updateStatus(retrievedVariant)"
          color="primary"
        >
          mdi-update
        </v-icon>
        <v-icon
          v-if="
            retrievedVariant &&
            retrievedVariant.nbrOfResponses > 0 &&
            $can('read', 'response')
          "
          small
          class="mr-2"
          @click="clickResponses(retrievedVariant)"
          color="primary"
        >
          mdi-format-list-checks
        </v-icon>
      </v-card-title>

      <v-card-text>
        <v-list dense>
          <VariantItemSteps
            :variant="retrievedVariant"
            :statusList="retrievedVariant.StatusSummarySteps"
            :commentStatusList="retrievedVariant.StatusSummaryComments"
            @clicked-steps="$emit('show', 'steps')"
          />

          <v-divider></v-divider>
          <VariantItemSpecs
            v-if="$feature('specification')"
            :variant="retrievedVariant"
            :statusList="retrievedVariant.StatusSummarySpecs"
            @clicked-specs="$emit('show', 'specs', $event)"
          />
          <VariantItemReqs
            v-else
            :variant="retrievedVariant"
            @clicked-reqs="$emit('show', 'reqs', $event)"
          />

          <div v-if="$feature('testScenarios')">
            <v-divider></v-divider>
            <v-list-item @click="$emit('show', 'test', variant.id)">
              <v-list-item-icon>
                <v-icon class="mr-2"> mdi-test-tube </v-icon>
              </v-list-item-icon>

              <span>Test Scenarios</span> <v-spacer></v-spacer>
            </v-list-item>
          </div>
          <div v-if="$feature('erpApplication')">
            <v-divider></v-divider>
            <v-card-subtitle
              >ERP Applications
              <span v-if="retrievedVariant.erp_applications"
                >({{ retrievedVariant.erp_applications.length }})</span
              ><v-btn icon @click="show = !show">
                <v-icon>{{
                  show ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn></v-card-subtitle
            >
            <v-expand-transition v-if="true">
              <div v-show="show && retrievedVariant.erp_applications">
                <v-card-text>
                  <v-list dense
                    ><v-list-item
                      v-for="app in retrievedVariant.erp_applications"
                      :key="app.id"
                      ><v-list-item-content
                        ><v-tooltip top open-delay="1000">
                          <template v-slot:activator="{ on, attrs }"
                            ><v-list-item-title v-bind="attrs" v-on="on">
                              <v-icon
                                v-if="$can('delete', 'erpapplication-variant')"
                                color="primary"
                                small
                                class="mr-2"
                                @click="removeApp(retrievedVariant, app)"
                              >
                                mdi-link-off
                              </v-icon>
                              <span v-if="app.Customized">*</span>
                              {{ app.Name }}
                            </v-list-item-title></template
                          >{{ app.Name }}</v-tooltip
                        ><v-tooltip top open-delay="1000">
                          <template v-slot:activator="{ on, attrs }"
                            ><v-list-item-subtitle v-bind="attrs" v-on="on"
                              >{{ app.Filter }}
                            </v-list-item-subtitle></template
                          >{{ app.Filter }}</v-tooltip
                        ></v-list-item-content
                      ></v-list-item
                    >
                  </v-list>
                  <v-btn
                    v-if="$can('create', 'erpapplication-variant')"
                    class="mx-2"
                    fab
                    elevation="0"
                    x-small
                    color="primary"
                    @click="clickAddApp()"
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                </v-card-text>
              </div>
            </v-expand-transition>
          </div>
          <!-- 
          <v-divider></v-divider>

          <v-list-item @click="$emit('show', 'training')">
            <v-list-item-icon>
              <v-icon class="mr-2">
                mdi-school
              </v-icon>
            </v-list-item-icon>
            <span>Training</span><v-spacer></v-spacer>
            <v-chip class="ma-2" x-small outlined>
              <FeathersVuexCount
                v-slot="{ total }"
                service="training"
                :params="{ query: { VariantId: item.id } }"
              >
                <span v-if="total"> {{ total }} </span>
                <span v-else>0</span>
              </FeathersVuexCount>
            </v-chip>
          </v-list-item> -->
        </v-list>
      </v-card-text>
      <VariantEdit
        v-if="detailDialog"
        v-model="detailDialog"
        :currentItem="currentItem"
        :process="currentItem.processId"
      />
    </v-card>
    <Response v-model="showResponse" :item="currentItem" />
    <VariantAddApp
      v-if="retrievedVariant"
      v-model="addAppDialog"
      :variantId="retrievedVariant.id"
    />
    <UpdateStatus
      v-if="currentItem"
      v-model="updateDialog"
      :VariantId="currentItem.id"
    />
  </div>
</template>

<script>
import VariantItemSteps from '@/components/Variant/VariantItemSteps';
import VariantItemReqs from '@/components/Variant/VariantItemReqs';
import VariantItemSpecs from '@/components/Variant/VariantItemSpecs';
import VariantEdit from '@/components/Variant/VariantEdit';
import VariantAddApp from '@/components/Variant/VariantAddApp';
import UpdateStatus from '@/components/Variant/UpdateStatus';
import Response from '@/components/Variant/VariantResponses';
import { makeGetMixin } from 'feathers-vuex';
import { subject } from '@casl/ability';
export default {
  name: 'VariantCard',
  props: ['variant', 'process'],
  components: {
    VariantItemSteps,
    VariantItemReqs,
    VariantItemSpecs,
    VariantEdit,
    VariantAddApp,
    Response,
    UpdateStatus,
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      name: 'retrievedVariant',
    }),
  ],
  data() {
    return {
      detailDialog: false,
      updateDialog: false,
      showResponse: false,
      currentItem: null,
      validInput: true,
      addAppDialog: false,
      show: false,
    };
  },

  methods: {
    checkEditVariant(item) {
      return this.$can('update', subject('variant', item));
    },
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickResponses(item) {
      this.currentItem = item;
      this.showResponse = true;
    },
    updateStatus(item) {
      this.currentItem = item;
      this.updateDialog = true;
    },
    clickAddApp() {
      this.addAppDialog = true;
    },

    async removeApp(variant, app) {
      // console.log(variant.id, app.id);
      const { erpapplication_variant, Variant } = this.$FeathersVuex.api;
      let result = await erpapplication_variant.find({
        query: {
          VariantId: variant.id,
          ErpApplicationId: app.id,
        },
      });
      if (result.data) {
        await this.$store.dispatch(
          'erpapplication-variant/remove',
          result.data[0].id
        );
        Variant.get(variant.id);
      }
    },
  },

  computed: {
    id() {
      return this.variant.id;
    },
  },
};
</script>

<style lang="scss" scoped></style>
