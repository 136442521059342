<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <ProcessCommentEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save()
            .then(async (saved) => {
              handleSaveResponse(
                saved.Comment,
                'comment',
                currentItem ? 'updated' : 'created'
              );
              await refresh();
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            });
        "
        @reset="reset"
        @remove="
          remove()
            .then(async (saved) => {
              handleSaveResponse(saved.Comment, 'comment', 'removed');
              await refresh();
            })
            .catch(async (error) => {
              handleErrorResponse(error);
              await refresh();
            });
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></ProcessCommentEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import ProcessCommentEditDialog from '@/components/ProcessStep/ProcessCommentEditDialog';

export default {
  name: 'ProcessCommentEdit',
  components: {
    FeathersVuexFormWrapper,
    ProcessCommentEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
    stepid: {},
  },
  computed: {
    item() {
      const { Comment } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        let newComment = new Comment();
        newComment.ProcessStepId = this.stepid;
        return newComment;
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Comment, Status, ProcessStep } = this.$FeathersVuex.api;
      await ProcessStep.find({ query: {} });
      await Comment.find({
        query: { ProcessStepId: this.stepid },
        include: {
          model: Status,
        },
      });
    },
  },
};
</script>
