<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <v-dialog :value="true" persistent max-width="1100px">
        <ProcessEditDialog
          :item="clone"
          @save="
            showDialog = false;
            save()
              .then(async (saved) => {
                handleSaveResponse(
                  saved.Name,
                  'process',
                  currentItem ? 'updated' : 'created'
                );
                await refresh();
              })
              .catch(async (error) => {
                handleErrorResponse(error);
                await refresh();
              });
          "
          @reset="reset"
          @remove="
            remove()
              .then(async (saved) => {
                handleSaveResponse(saved.Name, 'process', 'removed');
                await refresh();
              })
              .catch(async (error) => {
                handleErrorResponse(error);
                await refresh();
              });
            showDialog = false;
          "
          @cancel="showDialog = false"
        ></ProcessEditDialog>
      </v-dialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

import ProcessEditDialog from '@/components/Process/ProcessEditDialog';

export default {
  name: 'ProcessEdit',
  components: {
    FeathersVuexFormWrapper,
    ProcessEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
  },
  computed: {
    item() {
      const { Process } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        return new Process();
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    async refresh() {
      const { Process } = this.$FeathersVuex.api;
      await Process.find({
        query: {},
      });
    },
  },
};
</script>
