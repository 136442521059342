<template>
  <FeathersVuexFormWrapper :item="item" watch v-if="showDialog">
    <template v-slot="{ clone, save, reset, remove }">
      <End2EndEditDialog
        :item="clone"
        @save="
          showDialog = false;
          save().then(handleSaveResponse).catch(errorHandler);
        "
        @reset="reset"
        @remove="
          remove();
          showDialog = false;
        "
        @cancel="showDialog = false"
      ></End2EndEditDialog>
    </template>
  </FeathersVuexFormWrapper>
</template>

<script>
import { FeathersVuexFormWrapper } from 'feathers-vuex';

import End2EndEditDialog from '@/components/End2End/End2EndEditDialog';

export default {
  name: 'End2EndEdit',
  components: {
    FeathersVuexFormWrapper,
    End2EndEditDialog,
  },
  props: {
    value: { type: Boolean },
    currentItem: {
      type: Object,
      required: false,
    },
  },
  computed: {
    item() {
      const { end2End } = this.$FeathersVuex.api;
      if (this.currentItem) {
        return this.currentItem;
      } else {
        return new end2End();
      }
    },

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    async handleSaveResponse(saved) {
      this.$toasted.global.success(
        `Process '${saved.Name}' saved successfully`
      );
      const { end2End } = await this.$FeathersVuex.api;
      end2End.find({
        query: {},
      });
      // Redirect to the newly-saved item
      // if (this.id === 'new') {
      //   this.$router.push({ params: { id: savedTodo._id } });
      // }
    },
    errorHandler(error) {
      let errorMessage = '';
      error.errors.map((item) => {
        errorMessage += item.message + '\n';
      });
      this.errorMessage = errorMessage;
      this.$toasted.global.error(errorMessage);
    },
  },
};
</script>
