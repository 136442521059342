<template>
  <v-container>
    <h1>Loxi a tool by Foocus bv</h1>
    &copy; {{ currentYear }}
    <v-card class="mx-auto mt-10" max-width="400" tile>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Version</v-list-item-title>
          <v-list-item-subtitle>{{ $appConfig.version }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>Backend</v-list-item-title>
          <v-list-item-subtitle>{{
            $appConfig.apiBaseUrl
          }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-card-actions>
        <v-spacer />
        <v-img class="mt-10" width="200" src="/logos/LoxiAnimated.gif"></v-img
        ><v-spacer
      /></v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style></style>
