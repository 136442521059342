<template>
  <div>
    <v-list-item @click="$emit('clicked-steps')">
      <v-list-item-icon>
        <v-icon class="mr-2"> mdi-arrow-decision </v-icon>
      </v-list-item-icon>

      <span>Process Steps</span> <v-spacer></v-spacer>
      <StatusListIndividual
        v-if="statusList && statusList.length > 0"
        :statusList="statusList"
      />
    </v-list-item>
    <v-list-item v-if="commentStatusList && commentStatusList.length > 0">
      <v-icon small class="mr-5" color="primary"> mdi-comment-quote </v-icon>
      <StatusListIndividual :statusList="commentStatusList" />
    </v-list-item>
  </div>
</template>
<script>
import { makeFindMixin } from 'feathers-vuex';
import StatusListIndividual from '@/components/StatusListIndividual';

export default {
  props: ['variant', 'statusList', 'commentStatusList'],
  mixins: [makeFindMixin({ service: 'process-step' })],
  components: { StatusListIndividual },
  computed: {
    processStepParams() {
      return { query: { VariantId: this.variant.id } };
    },
  },
};
</script>
