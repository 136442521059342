var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v("Process Steps "),(_vm.processUrl)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",on:{"click":function($event){_vm.openWindow(
              _vm.$feature('lucidChartIntegration') ? _vm.lucidUrl : _vm.processUrl
            )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-search-outline ")])]}}],null,false,3925491795)},[_c('span',[_vm._v("Process Flows")])]):_vm._e()],1),_c('v-card-text',[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.filteredProcessSteps),function(step){return _c('v-list-item',{key:step.id},[_c('v-row',{staticClass:"mb-1"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-list-item-content',{staticClass:"text-pre-wrap py-0"},[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(
                  step.Number +
                  '. ' +
                  step.Name +
                  ' (' +
                  step.priority.Name +
                  ')'
                )}}),(step.Description)?_c('span',{staticClass:"mb-3"},[_vm._v(_vm._s(step.Description))]):_vm._e(),(step.fields && step.fields.length > 0)?_c('div',[_c('i',[_vm._v("Fields:")]),_c('ul',_vm._l((step.fields),function(f){return _c('li',{key:f.id},[_vm._v(" "+_vm._s(f.Name)+": "+_vm._s(f.Description)+" "),(f.fieldvaluelists && f.fieldvaluelists.length > 0)?_c('div',[_c('i',[_vm._v("Possible Values:")]),_c('ul',_vm._l((f.fieldvaluelists),function(fv){return _c('li',{key:fv.id},[_vm._v(" "+_vm._s(fv.Code)+": "+_vm._s(fv.Description)+" ")])}),0)]):_vm._e()])}),0)]):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.user.vendor.ResponsePhase == 2)?_c('ProcessResponse',{attrs:{"processStep":step,"variantResponse":_vm.response.filter((f) => f.VendorId == _vm.user.vendor.id)}}):_vm._e()],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }