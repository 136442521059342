import feathersClient from '@/feathers-client';
import axios from 'axios';

async function getHeader() {
  let tokenInfo = await feathersClient
    .service('tokens')
    .find({ query: { Application: 'LucidChart' } });

  let config = {
    headers: {
      Authorization: 'Bearer ' + tokenInfo.data[0].AccessToken,
      'Lucid-Api-Version': 1,
    },
  };
  return config;
}

async function getDocumentEmbed(payload, end2end = false) {
  let url = `https://api.lucid.co/documents/${payload.ExternalDocumentId}/embeds`;
  let body = {
    embedVersion: 'latest-version',
  };

  let embed = null;
  try {
    embed = await axios.post(url, body, await getHeader());
  } catch (error) {
    await useRefresToken();
    //retry
    embed = await axios.post(url, body, await getHeader());
  }
  // console.log('embed', embed);
  if (end2end) {
    await feathersClient
      .service('end-2-end')
      .patch(payload.id, { EmbedId: embed.data.embedId });
  } else {
    await feathersClient
      .service('process')
      .patch(payload.id, { EmbedId: embed.data.embedId });
  }
}

async function getDocumentEmbedToken(process) {
  let url = `https://api.lucid.co/documents/${process.ExternalDocumentId}/embeds/${process.EmbedId}/token`;
  let tokenEmbed = null;
  try {
    tokenEmbed = await axios.post(url, null, await getHeader());
  } catch (error) {
    // console.log(error.response);
    if (error.response.status == 401) {
      // Reauthenticate with refresh token
      await useRefresToken();
      //retry
      tokenEmbed = await axios.post(url, null, await getHeader());
    }
  }
  // console.log('TokenEmbed', tokenEmbed);
  return tokenEmbed.data;
}

async function getDocumentPickerToken(origin) {
  // console.log('loc', origin);
  let url = `https://api.lucid.co/documents/pickerToken`;
  let body = {
    product: 'lucidchart',
    targetOrigin: origin,
  };
  let tokenEmbed = null;
  try {
    tokenEmbed = await axios.post(url, body, await getHeader());
  } catch (error) {
    if (error.response.status == 401) {
      // Reauthenticate with refresh token
      await useRefresToken();
      //retry
      tokenEmbed = await axios.post(url, body, await getHeader());
    }
  }
  return tokenEmbed.data;
}

async function getDocumentInfo(process, docId) {
  let documentId = null;
  if (docId) documentId = docId;
  else documentId = process.ExternalDocumentId;

  let url = `https://api.lucid.co/documents/${documentId}/contents`;
  let docContents = null;
  try {
    docContents = await axios.get(url, await getHeader());
  } catch (error) {
    if (error.response.status == 401) {
      await useRefresToken();
      //retry
      docContents = await axios.get(url, await getHeader());
    }
  }

  return docContents.data;
}

async function getDocumentImage(documentId, pageIndex) {
  let url = `https://api.lucid.co/documents/${documentId}?crop=content&page=${pageIndex}`;
  let headers = await getHeader();
  headers.headers.Accept = 'image/png;dpi=300';
  let image = null;
  try {
    image = await axios.get(url, {
      responseType: 'blob',
      headers: headers.headers,
    });
  } catch (error) {
    if (error.response.status == 401) {
      await useRefresToken();
      //retry
      image = await axios.get(url, {
        responseType: 'blob',
        headers: headers.headers,
      });
    }
  }
  if (image) {
    image = new Promise((callback) => {
      let reader = new FileReader();
      reader.onload = function () {
        callback(this.result);
      };
      reader.readAsDataURL(image.data);
    });
  } else image = null;

  return image;
}

async function useRefresToken() {
  console.log('Reauthenticate');
  let refreshToken = await feathersClient
    .service('tokens')
    .find({ query: { Application: 'LucidChart' } });
  let url = 'https://api.lucid.co/oauth2/token';
  let body = {
    refresh_token: refreshToken.data[0].RefreshToken,
    client_id: refreshToken.data[0].ClientId,
    client_secret: refreshToken.data[0].ClientSecret,
    grant_type: 'refresh_token',
  };

  let accessToken = await axios.post(url, body);
  await feathersClient.service('tokens').patch(refreshToken.data[0].id, {
    AccessToken: accessToken.data.access_token,
    RefreshToken: accessToken.data.refresh_token,
  });
}

function parseDocumentInfo(document) {
  let pages = [];
  // console.log(document);

  document.pages.forEach((page) => {
    let newPage = {};
    newPage.id = page.id;
    newPage.index = page.index;
    newPage.title = page.title;
    newPage.blocks = [];
    // console.log('page', page);

    let result = page.items.shapes;
    // Filter out only the shapes of type Process Block
    result = result.filter((item) => item.class == 'ProcessBlock');
    // get only the important fields
    result = result.flatMap((item) => {
      let newitem = {};
      newitem.id = item.id;
      newitem.text = item.textAreas[0].text;
      return item.textAreas[0].text ? newitem : [];
    });
    // console.log('result', result);
    newPage.blocks = [...result];
    // console.log('newpage', newPage);
    pages.push(newPage);
  });
  // console.log('pages', pages);
  return pages;
}

export {
  getDocumentEmbed,
  getDocumentEmbedToken,
  getDocumentInfo,
  getDocumentImage,
  getDocumentPickerToken,
  parseDocumentInfo,
};
