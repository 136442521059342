import pdfMake from 'pdfmake/build/pdfmake';
import feathersClient from '@/feathers-client';
import { config } from '@/config';
import { getImage } from './PDFImageGetter';

const styles = {
  headerTop: {
    fontSize: 16,
    bold: true,
    color: '#D93831',
    // color: '#36508D',
    margin: [0, 0, 0, 10],
  },

  end2endHeader: {
    fontSize: 16,
    bold: true,
    color: '#D93831',
    // color: '#36508D',
    margin: [0, 0, 0, 5],
  },
  processHeader: {
    fontSize: 16,
    // bold: true,
    color: '#D93831',
    // color: '#36508D',
    margin: [0, 0, 0, 5],
  },
  variantHeader: {
    fontSize: 14,
    // bold: true,
    color: '#D93831',
    // color: '#36508D',
    margin: [0, 0, 0, 5],
  },
  subHeader: {
    fontSize: 13,
    // bold: true,
    color: '#D93831',
    // color: '#36508D',
    margin: [0, 0, 0, 5],
  },
  table: {
    margin: [0, 5, 0, 5],
  },
  tableHeader: {
    color: '#FFFFFF',
    fillColor: '#656565',
    margin: [0, 6, 0, 0],
  },
};

const fonts = {
  Roboto: {
    normal: `${config.clientBaseUrl}/fonts/Roboto/Roboto-Regular.ttf`,
    bold: `${config.clientBaseUrl}/fonts/Roboto/Roboto-Medium.ttf`,
    italics: `${config.clientBaseUrl}/fonts/Roboto/Roboto-Italic.ttf`,
    bolditalics: `${config.clientBaseUrl}/fonts/Roboto/Roboto-MediumItalic.ttf`,
  },
  Calibri: {
    normal: `${config.clientBaseUrl}/fonts/Calibri/Calibri-Regular.ttf`,
    bold: `${config.clientBaseUrl}/fonts/Calibri/Calibri-Bold.ttf`,
    italics: `${config.clientBaseUrl}/fonts/Calibri/Calibri-Italic.ttf`,
    bolditalics: `${config.clientBaseUrl}/fonts/Calibri/Calibri-Bold-Italic.ttf`,
  },
  Rotis: {
    normal: `${config.clientBaseUrl}/fonts/Rotis/RotisSemiSerifStd.otf`, //'http://localhost:8080/RotisSemiSerifStd.otf',
  },
};

function currentDate() {
  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${yyyy}-${mm}-${dd}`;
}

async function generateDocument(type, payload, external) {
  if (pdfMake.vfs == undefined) {
    var pdfFonts = require('pdfmake/build/vfs_fonts.js');
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  pdfMake.fonts = fonts;

  let docDefinition = {
    pageSize: 'A4',
    pageMargins: [50, 85, 50, 50],
    // eslint-disable-next-line no-unused-vars
    header: function (currentPage, pageCount, pageSize) {
      if (currentPage != 1)
        return [
          {
            columns: [
              {
                image: 'logo',
                fit: [100, 40],
                // height: 40,
                margin: [25, 25, 0, 100],
              },
              {
                image: 'logoFoocus',
                fit: [100, 40],
                alignment: 'right',
                // height: 40,
                margin: [0, 20, 15, 100],
              },
            ],
          },
        ];
    },
    footer: function (currentPage, pageCount) {
      if (currentPage != 1)
        return {
          columns: [
            {
              text: currentDate(),
              margin: [25, 20, 0, 0],
              style: [{ color: '#56575a' }],
            },
            {
              text: currentPage.toString() + '/' + pageCount,
              alignment: 'right',
              margin: [0, 20, 25, 0],
              style: [{ color: '#56575a' }],
            },
          ],
        };
    },

    content: [],
    defaultStyle: {
      font: 'Roboto',
      fontSize: 10,
    },
    images: {
      // logo: {
      //   url: `${config.clientBaseUrl}/logos/${config.customer.logo}`,
      // },
      logo: {
        url: `${config.clientBaseUrl}/logos/${config.customer.logoLarge}`,
      },
      logoFoocus: {
        url: `${config.clientBaseUrl}/logos/FoocusDark.png`,
      },
      foocusMarketing: {
        url: `${config.clientBaseUrl}/logos/FoocusMarketing.jpg`,
      },
    },
    styles: styles,
  };

  docDefinition.content.push(printFrontPage(type, payload, external));
  docDefinition.content.push({
    toc: {
      title: {
        text: 'Table of Contents',
        style: 'headerTop',
        pageBreak: 'before',
      },
    },
  });

  let filename = null;
  if (type == 'process') {
    let processes = await printProcess(payload, true, external);
    docDefinition.content.push(processes);
    filename = `Process ${
      config.customer.prefix
    }${payload.Number.toString().padStart(4, '0')} ${
      payload.Name
    } ${currentDate()}`;
  } else if (type == 'end2end') {
    let end2ends = await printEnd2End(payload, external);
    docDefinition.content.push(end2ends);
    filename = `E2E${payload.Number.toString().padStart(2, '0')} ${
      payload.Name
    } ${currentDate()}`;
  } else if (type == 'full') {
    let departments = await printDepartment(external);
    docDefinition.content.push(departments);
    docDefinition.content.push(await printSpecifications());
    filename = `Full Process List ${currentDate()}`;
  }

  pdfMake.createPdf(docDefinition).download(filename + '.pdf');
}

function printFrontPage(type, payload, external) {
  let frontPageFields = [];
  let filename = null;
  if (type == 'process') {
    filename = `Process ${
      config.customer.prefix
    }${payload.Number.toString().padStart(4, '0')} ${payload.Name}`;
  } else if (type == 'end2end') {
    filename = `E2E${payload.Number.toString().padStart(2, '0')} ${
      payload.Name
    }`;
  } else if (type == 'full') {
    filename = 'Full Process List';
  }
  frontPageFields.push({
    image: 'logo',
    alignment: 'center',
    width: 250,
    margin: [0, 100, 0, 100],
  });
  if (!external) {
    frontPageFields.push({
      text: filename,
      fontSize: 28,
      color: '#525356',
      font: 'Rotis',
      alignment: 'center',
    });
  } else {
    frontPageFields.push({
      text: filename,
      fontSize: 28,
      color: '#525356',
      alignment: 'center',
    });
  }
  if (external) {
    frontPageFields.push({
      image: 'foocusMarketing',
      alignment: 'center',
      width: 250,
      margin: [0, 50, 0, 0],
    });
  }
  return frontPageFields;
}

async function printDepartment(external) {
  let departmentList = [];
  let departments = await feathersClient.service('department').find({
    query: { detail: true },
  });

  if (departments && departments.data && departments.data.length > 0) {
    let departmentsExtracted = await Promise.all(
      departments.data.map(async (d) => {
        if (d.processes && d.processes.length > 0) {
          let processExtracted = [];
          processExtracted.push({
            text: d.Name,
            style: 'headerTop',
            tocItem: true,
            tocMargin: [0, 0, 0, 0],
            pageBreak: 'before',
          });
          let processList = d.processes;
          processList = processList.sort((a, b) => {
            return a.Order - b.Order || a.Number - b.Number;
          });

          for (const [i, p] of processList.entries()) {
            processExtracted.push(await printProcess(p, i != 0, external));
          }

          // processExtracted.push(
          //   await Promise.all(
          //     processList.map(async (p, i) => {
          //       let ps = await printProcess(p, i != 0, external);
          //       return ps;
          //     })
          //   )
          // );
          return processExtracted;
        }
      })
    );
    departmentList.push(departmentsExtracted);
  }
  return departmentList;
}

async function printEnd2End(payload, external) {
  let end2endList = [];
  let end2end = await feathersClient.service('end-2-end').find({
    query: { id: payload.id },
  });
  end2endList.push({
    text: `E2E${payload.Number.toString().padStart(2, '0')} ${payload.Name}`,
    style: 'end2endHeader',
    tocItem: true,
    tocMargin: [0, 0, 0, 0],
    pageBreak: 'before',
  });
  let breakBefore = true;

  let image = await getImage({ e2e: payload });
  if (!image) breakBefore = false; // Do not break page if no end 2 end image

  end2endList.push(image);

  let processList =
    end2end.data && end2end.data.length > 0 ? end2end.data[0].processes : null;
  processList = processList.sort((a, b) => {
    return a.Order - b.Order || a.Number - b.Number;
  });
  if (processList && processList.length > 0) {
    // eslint-disable-next-line no-unused-vars
    for (const [i, p] of processList.entries()) {
      let processExtracted = await printProcess(p, breakBefore, external);
      end2endList.push(processExtracted);
    }

    // let processExtracted = await Promise.all(
    //   processList.map(async (p, i) => {
    //     let ps = await printProcess(p, i != 0, external);
    //     return ps;
    //   })
    // );

    // end2endList.push(processExtracted);
  }
  return end2endList;
}

async function printProcess(payload, breakbefore, external) {
  let variant = await feathersClient.service('variant').find({
    query: { ProcessId: payload.id, $sort: { Number: 1 } },
  });

  variant = variant.data;

  let image = await getImage({ process: payload });

  let variantsExtracted = [];
  // eslint-disable-next-line no-unused-vars
  for (const [i, v] of variant.entries()) {
    variantsExtracted.push(await VariantPrint(v, external));
  }

  // let variantsExtracted = await Promise.all(
  //   variant.map(async (v) => {
  //     let ps = await VariantPrint(v, external);
  //     return ps;
  //   })
  // );

  let input = null;
  if (payload.Input) {
    input = [
      { text: 'Input:', bold: true },
      { text: payload.Input, margin: [0, 0, 0, 10] },
    ];
  }
  let output = null;
  if (payload.Output) {
    output = [
      { text: 'Output:', bold: true },
      { text: payload.Output, margin: [0, 0, 0, 10] },
    ];
  }

  let process = [
    {
      text: `${config.customer.prefix}${payload.Number.toString().padStart(
        4,
        '0'
      )} ${payload.Name}`,
      style: 'processHeader',
      tocItem: true,
      tocMargin: [10, 0, 0, 0],
      pageBreak: breakbefore ? 'before' : '',
    },
    { text: 'Process Overview', style: 'variantHeader' },
    input,
    output,

    { text: payload.Output, margin: [0, 0, 0, 10] },
    image ? image : {},
    [...variantsExtracted],
  ];

  return process;
}

async function VariantPrint(v, external) {
  let result = await feathersClient.service('process-step').find({
    query: { VariantId: v.id, Obsolete: false, $sort: { Number: 1 } },
  });
  result.data = result.data.filter(
    (ps) => !(ps.status.Closed == true && ps.status.Progress == 0)
  );
  let variant = [];
  let fullnumber =
    config.customer.prefix +
    v.process.Number.toString().padStart(4, '0') +
    '.' +
    v.Number.toString().padStart(2, '0');
  // variant.push({ text: 'Variants', style: 'subHeader', pageBreak: 'before' });
  variant.push({
    text: `${fullnumber} ${v.Name}`,
    style: 'variantHeader',
    tocItem: true,
    tocMargin: [15, 0, 0, 0],
    tocStyle: { fontSize: 9 },
    tocNumberStyle: { fontSize: 9 },
    pageBreak: 'before',
  });

  variant.push(await printERPApplications(v));
  let input = null;
  if (v.Input) {
    input = [
      { text: 'Input:', bold: true, margin: [0, 10, 0, 0] },
      { text: v.Input, margin: [0, 0, 0, 10] },
    ];
    variant.push(input);
  }
  let output = null;
  if (v.Output) {
    output = [
      { text: 'Output:', bold: true, margin: [0, 0, 0, 0] },
      { text: v.Output, margin: [0, 0, 0, 10] },
    ];
    variant.push(output);
  }

  let image = await getImage({ process: v.process, variant: v });

  variant.push(image ? image : {}); //;
  result.data.forEach((p, i) => {
    p.Description = p.Description ? p.Description : '';
    let fields = printFields(p, i == result.data.length - 1);
    // if (fields && fields.length > 0) {
    //   p.Description = p.Description + '\n\n';
    //   fields.forEach((f) => {
    //     p.Description ? (p.Description = p.Description + f + '\n') : '';
    //   });
    // }

    let buildTable = {
      table: {
        headerRows: 1,
        widths: ['*'],
        heights: [25],

        body: [],
      },

      layout: {
        vLineWidth: function () {
          return 1;
        },
        LineWidth: function () {
          return 1;
        },

        vLineColor: function () {
          return '#a5a5a5';
        },
        hLineColor: function () {
          return '#a5a5a5';
        },
      },
    };

    let priority = '';

    if (external) {
      priority = ` (${p.priority.Name})`;
    }

    buildTable.table.body.push([
      {
        border: [true, i == 0 ? true : false, true, false],
        text: `${p.Number}. ${p.Name}${priority}`,
        color: '#FFFFFF',
        margin: [0, 6, 0, 0],
        fillColor: '#656565',
      },
    ]);

    buildTable.table.body.push([
      {
        text: p.Description,
        margin: [2, 3, 2, 3],
        border: [
          true,
          false,
          true,
          i == result.data.length - 1 && fields && fields.length == 0
            ? true
            : false,
        ],
      },
    ]);

    // let fields = printFields(p);
    if (fields && fields.length > 0) {
      fields.forEach((f) => {
        buildTable.table.body.push([f]);
      });
    }

    variant.push(buildTable);
  });
  if (external) variant.push(await printSpecifications(v));
  return variant;
}

async function printERPApplications(v) {
  let variant = await feathersClient.service('variant').get(v.id);

  let apps = [];
  if (variant.erp_applications && variant.erp_applications.length > 0) {
    variant.erp_applications.forEach((p) => {
      apps.push(p.Name);
    });

    return [
      { text: 'ERP Apps:', bold: true },
      {
        // to treat a paragraph as a bulleted list, set an array of items under the ul key
        ul: apps,
      },
    ];
  }
  return [];
}

function printFields(processStep, last) {
  let fields = [];
  if (processStep && processStep.fields && processStep.fields.length > 0) {
    fields.push({
      text: 'Fields:',
      decoration: 'underline',
      border: [true, false, true, false],
    });
    processStep.fields.forEach((f) => {
      fields.push({
        text: [
          { text: `${f.Name}${f.Description ? ': ' : ''}`, bold: true },
          { text: `${f.Description ? f.Description : ''}`, bold: false },
        ],
        border: [true, false, true, false],
        bold: true,
      });
      if (f.fieldvaluelists && f.fieldvaluelists.length > 0) {
        f.fieldvaluelists = f.fieldvaluelists.sort(
          (a, b) => a.Number - b.Number
        );
        fields.push({
          text: 'Possible Values:',
          decoration: 'underline',
          border: [true, false, true, false],
        });
        f.fieldvaluelists.forEach((fv, i) => {
          let value = fv.Value ? fv.Value : '';
          let code = fv.Code ? ` (${fv.Code})` : '';
          let description = fv.Description ? `: ${fv.Description}` : '';
          fields.push({
            text: [
              { text: `- ${value}${code}`, bold: true },
              { text: `${description}`, bold: false },
            ],
            border: [
              true,
              false,
              true,
              i == f.fieldvaluelists.length - 1 && last ? true : false,
            ],
          });
          // fields.push(`- ${value}${code}${description}`);
        });
      }
    });
    return fields;
  }
  return [];
}

async function printSpecifications(payload) {
  let variant = {};
  if (payload) {
    variant = await feathersClient.service('variant').get(payload.id);
  } else {
    variant.specifications = await feathersClient
      .service('specification')
      .find();
    variant.specifications = variant.specifications.data.filter(
      (s) => s.variants.length == 0
    );
  }

  let specs = [];

  if (variant && variant.specifications && variant.specifications.length > 0) {
    variant.specifications = variant.specifications.filter(
      (p) =>
        p.specification_type.Active == true &&
        p.Obsolete == false &&
        !(p.status.Progress == 0 && p.status.Closed == true)
    );
    variant.specifications = variant.specifications.sort(
      (a, b) => a.Number - b.Number
    );
    if (payload) {
      specs.push({
        text: 'Specification Overview',
        style: 'variantHeader',
        margin: [0, 20, 0, 5],
      });
    } else {
      specs.push({
        text: 'General Specifications',
        style: 'headerTop',
        tocItem: true,
        tocMargin: [0, 0, 0, 0],
        pageBreak: 'before',
      });
    }

    let buildTable = {
      table: {
        headerRows: 1,
        // widths: ['*'],
        heights: [25],

        body: [
          [
            { text: 'Number', style: 'tableHeader' },
            { text: 'Type', style: 'tableHeader' },
            { text: 'Name', style: 'tableHeader' },
            { text: 'Description', style: 'tableHeader' },
            { text: 'Priority', style: 'tableHeader' },
          ],
        ],
      },

      layout: {
        vLineWidth: function () {
          return 1;
        },
        LineWidth: function () {
          return 1;
        },

        vLineColor: function () {
          return '#a5a5a5';
        },
        hLineColor: function () {
          return '#a5a5a5';
        },
      },
    };
    variant.specifications.forEach((p) => {
      buildTable.table.body.push([
        {
          text:
            p.specification_type.Code + p.Number.toString().padStart(4, '0'),
        },
        { text: p.specification_type.Type },
        { text: p.Description1 },
        { text: p.Description3 },
        { text: p.priority.Name },
      ]);
    });
    specs.push(buildTable);

    return specs;
  }
}

export { generateDocument };
