import { config } from '@/config';
import { getDocumentImage } from '@/utils/LucidChartFunctions';

// let baseurl = `https://res.cloudinary.com/foocus/image/upload/w_1000/fl_sanitize/v${Date.now()}/`;
let baseurl = `https://res.cloudinary.com/foocus/image/upload/w_2000,c_lpad,fl_ignore_aspect_ratio/fl_sanitize/v${Date.now()}/`;

function urlContentToDataUri(url) {
  return fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((callback) => {
          let reader = new FileReader();
          reader.onload = function () {
            callback(this.result);
          };
          reader.readAsDataURL(blob);
        })
    );
}

async function getImage({ e2e = null, process = null, variant = null } = {}) {
  let mode = config.customer.imageType; //svg, png, lucid
  let prefix = config.customer.imagePrefix;

  let filename = null;
  let documentId = null;
  let pageIndex = null;
  if (e2e) {
    filename = `E2E${e2e.Number.toString().padStart(2, '0')}`;
  }
  if (process && !variant) {
    filename = prefix
      ? `${config.customer.prefix}${process.Number.toString().padStart(4, '0')}`
      : `${process.Number.toString()}`;
    documentId = process.ExternalDocumentId;
    pageIndex = 1;
  }
  if (process && variant) {
    filename = prefix
      ? `${config.customer.prefix}${process.Number.toString().padStart(
          4,
          '0'
        )}.${variant.Number.toString().padStart(2, '0')}`
      : `${process.Number.toString()}.${variant.Number.toString()}`;
    documentId = process.ExternalDocumentId;
    pageIndex = variant.PageIndexes
      ? variant.PageIndexes.split(',')[0]
      : variant.Number + 1;
  }

  let url = `${baseurl}${config.customer.prefix}/${filename}`;

  if (mode == 'svg') {
    let res = null;
    let ressvg = null;
    try {
      res = await fetch(`${url}.svg`);
      ressvg = await res.text();
      return ressvg
        ? {
            svg: ressvg,
            fit: [500, 550],
            alignment: 'center',
            margin: [0, 10, 0, 10],
          }
        : null;
    } catch (error) {
      // console.log(error);
    }
  } else if (mode == 'png') {
    let dataUri = await urlContentToDataUri(`${url}.png`);
    return dataUri && dataUri.length > 5
      ? {
          image: dataUri,
          fit: [500, 550],
          alignment: 'center',
          margin: [0, 10, 0, 10],
          link: `${url}.svg`,
        }
      : null;
  } else if (mode == 'lucid') {
    if (documentId && pageIndex) {
      let dataUri = await getDocumentImage(documentId, pageIndex);

      return dataUri && dataUri.length > 5
        ? {
            image: dataUri,
            fit: [500, 550],
            alignment: 'center',
            margin: [0, 10, 0, 10],
          }
        : null;
    }
  }

  return null;
}

export { getImage };
