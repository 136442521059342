<template>
  <v-container>
    <div v-if="process" class="caption">
      <router-link
        v-if="$store.state.activeEnd2End.Name"
        :to="{
          name: 'Process',
          params: { id: $store.state.activeEnd2End.id },
        }"
        >E2E{{ $store.state.activeEnd2End.Number }} -
        {{ $store.state.activeEnd2End.Name }}</router-link
      ><v-icon v-if="$store.state.activeEnd2End.Name" small
        >mdi-chevron-right</v-icon
      >
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: process.id, number: process.Number },
        }"
        ><b
          >{{ $appConfig.customer.prefix
          }}{{ process.Number.toString().padStart(4, '0') }} -
          {{ process.Name }}</b
        ></router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="variant in sortedVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <FeathersVuexGet
      v-slot="{ item: process }"
      service="process"
      :id="id"
      :watch="['id']"
      ><div v-if="process">
        <h1>
          {{ process.Name }}
        </h1>
        <v-list-item v-if="process.Input" two-line>
          <v-list-item-content>
            <v-list-item-title>Input</v-list-item-title>
            <v-list-item-subtitle class="pre">{{
              process.Input
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="process.Output" two-line>
          <v-list-item-content>
            <v-list-item-title>Output</v-list-item-title>
            <v-list-item-subtitle>{{ process.Output }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-row>
          <v-col class="ma-4">
            <v-row class="mb-5">
              <iframe
                frameBorder="0"
                v-if="embedToken"
                width="1000px"
                height="500px"
                :src="LucidUrl"
              ></iframe>
              <v-carousel
                v-if="images.length > 0"
                :height="images.length > 1 ? '600px' : '500px'"
                width="100%"
                :hide-delimiters="images.length > 1 ? false : true"
                :show-arrows="images.length > 1 ? true : false"
              >
                <v-row justify="center">
                  <v-col cols="12" md="6">
                    <v-carousel-item
                      class="mb-15"
                      v-for="(item, i) in images"
                      :key="i"
                      :src="item"
                      contain
                      max-height="500"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                      @click="openWindow(item)"
                    ></v-carousel-item>
                  </v-col>
                </v-row>
              </v-carousel> </v-row
            ><v-row
              ><v-spacer></v-spacer>
              <span v-if="embedToken">
                <v-icon @click="openWindow(LucidUrl)">
                  mdi-image-search-outline
                </v-icon>

                <v-icon
                  v-if="$can('update', 'variant')"
                  class="ml-5"
                  @click="getSteps(process)"
                >
                  mdi-table-sync
                </v-icon></span
              >
            </v-row></v-col
          >
        </v-row>
        <v-row
          ><v-card
            v-if="sharePointUrl || (filteredJira && filteredJira.fields)"
            class="mx-auto"
            width="50%"
            tile
          >
            <v-list>
              <v-list-item-title> Extra Information</v-list-item-title>
              <v-list-item-group color="primary">
                <v-list-item
                  v-if="filteredJira && jiraUrl"
                  @click="
                    openTab(
                      `https://leah4hifferman.atlassian.net/issues/?jql=status != Closed AND 'Parent' = ${process.IssueTrackingId} order by created DESC`
                    )
                  "
                >
                  <v-list-item-icon>
                    <v-icon> mdi-list-status </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><JiraTasksProcess :list="filteredJira"
                    /></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon> mdi-file-multiple-outline </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span
                        ><a
                          v-if="sharePointUrl"
                          target="_blank"
                          :href="sharePointUrl"
                          >Documents</a
                        ></span
                      ></v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-row>

        <v-btn
          v-if="$can('create', 'variant')"
          elevation="0"
          class="ma-8"
          fab
          small
          color="primary"
          @click="clickAdd()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-row>
          <v-col
            v-for="item in process.variants
              .slice()
              .sort((a, b) => a.Number - b.Number)"
            :key="item.id"
            cols="12"
            sm="6"
            md="4"
          >
            <VariantCard
              :variant="item"
              :process="process"
              @show="showDetails(arguments, item)"
            />
          </v-col>
        </v-row>
      </div>
    </FeathersVuexGet>

    <VariantEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :process="process.id"
    />

    <v-dialog v-model="importSteps" max-width="500px">
      <v-card flat>
        <v-card-title>
          <span class="headline">Import Variants</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="compareImportedVariants"
                sort-by="Number"
                disable-pagination
                hide-default-footer
                show-select
                :item-class="row_classes"
                v-model="importSelected"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelImport()">Cancel</v-btn>
          <v-btn color="primary" @click="saveImport()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import VariantCard from '@/components/Variant/VariantCard';
import JiraTasksProcess from '@/components/Process/JiraTasksProcess';

import VariantEdit from '@/components/Variant/VariantEdit';
import axios from 'axios';
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
  getDocumentInfo,
  parseDocumentInfo,
} from '@/utils/LucidChartFunctions';

async function imageExists(url) {
  var result = false;
  result = await axios
    .head(url)
    .then((res) => res.status == 200)
    .catch(() => false);
  // console.log(url, result);
  return result;
}

export default {
  name: 'ProcessDetail',
  data() {
    return {
      detailDialog: false,
      companyAddDialog: false,
      importSteps: false,
      images: [],
      embedToken: null,
      compareImportedVariants: [],
      importSelected: [],
      headers: [
        {
          text: 'Imported Text',
          value: 'text',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Current Text',
          value: 'loxiText',
          sortable: true,
          groupable: false,
        },
      ],
    };
  },
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'id',
      watch: 'id',
    }),

    makeFindMixin({
      service: 'jira',
      watch: 'process.IssueTrackingId',
    }),
    makeFindMixin({
      service: 'tokens',
    }),
  ],
  components: {
    VariantCard,
    VariantEdit,
    JiraTasksProcess,
  },
  watch: {
    '$route.params.id': {
      handler: async function () {
        if (this.$feature('lucidChartIntegration')) {
          await feathersClient.service('process').get(this.id);
          await this.LucidEmbed();
        } else {
          await this.getImages();
        }
        await feathersClient.service('jira').find({
          query: {
            type: this.$appConfig.externalIssues,
            process: this.process ? this.process.IssueTrackingId : 0,
          },
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    row_classes(item) {
      if (item.text != item.loxiText) {
        return 'highlight';
      }
    },
    openTab(url) {
      window.open(url, '_blank');
    },
    showDetails: function (type, item) {
      // console.log(type);
      if (type[0] == 'steps') {
        this.$router.push({
          name: 'processStep',
          params: { id: item.id },
        });
      } else if (type[0] == 'reqs') {
        this.$router.push({
          name: 'processRequirement',
          params: { id: item.id, type: type[1] },
        });
      } else if (type[0] == 'specs') {
        this.$router.push({
          name: 'specification',
          params: { id: item.id, type: type[1] },
        });
      } else if (type[0] == 'test') {
        this.$router.push({
          name: 'testscenarios',
          params: { id: item.id, type: type[1] },
        });
      }
    },
    async getImages() {
      let url = '';
      this.images = [];

      url =
        'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
        Date.now() +
        '/' +
        this.$appConfig.customer.prefix +
        '/' +
        this.number;

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${url}${i == 0 ? '' : '-' + i.toString()}.svg`;
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
        }
      }

      url =
        'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
        Date.now() +
        '/' +
        this.$appConfig.customer.prefix +
        '/' +
        this.$appConfig.customer.prefix +
        this.number.toString().padStart(4, '0');

      for (let i = 0; i <= 3; i++) {
        let imageCheck = `${url}${i == 0 ? '' : '-' + i.toString()}.svg`;
        // console.log(i, imageCheck);
        if (await imageExists(imageCheck)) {
          this.images.push(imageCheck);
          // console.log(this.images);
        }
      }
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    clickAdd() {
      this.detailDialog = true;
    },
    async LucidEmbed() {
      if (this.process && this.process.ExternalDocumentId) {
        if (!this.process.EmbedId) {
          await getDocumentEmbed(this.process);
        }
        this.embedToken = await getDocumentEmbedToken(this.process);
      }
    },
    async getSteps(process) {
      let result = await getDocumentInfo(process);

      result = parseDocumentInfo(result)[0];

      let variant = this.process.variants.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.ExternalId = item.ExternalId;
        newitem.text = item.Name;
        return newitem;
      });
      this.compareImportedVariants = result.blocks.map((item) => {
        let newitem = {};
        newitem.id = item.id;
        newitem.text = item.text;
        let loxi = variant.filter((item2) => item2.ExternalId == item.id);
        if (loxi.length == 1) {
          newitem.loxiId = loxi[0].id;
          newitem.loxiText = loxi[0].text;
        }

        return newitem;
      });

      this.importSteps = true;
    },
    cancelImport() {
      this.importSteps = false;
    },
    async saveImport() {
      if (this.importSelected.length > 0) {
        this.importSelected.forEach(async (item, index) => {
          // console.log(item.id);
          const { Variant, Process } = this.$FeathersVuex.api;
          if (!item.loxiId) {
            let newVariant = new Variant();
            newVariant.Number = index + 1;
            newVariant.ExternalId = item.id;
            newVariant.ApplicationId = 1;
            newVariant.Name = item.text;
            newVariant.ProcessId = this.process.id;
            await newVariant.save();
          } else {
            let newVariant = new Variant();
            newVariant.Name = item.text;
            // console.log(item.loxiId, newVariant);
            await feathersClient.service('variant').patch(item.loxiId, {
              Name: item.text,
            });
          }
          await Process.find({ query: { id: this.process.id } });
        });
      }
      this.importSteps = false;
    },
    filterStatusList(item) {
      if (this.statusOverviewLatestQuery) {
        return this.statusOverviewLatestQuery.response.filter(
          (s) => s.VariantId == item.id
        );
      } else return [];
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    number() {
      return this.$route.params.number;
    },
    LucidUrl() {
      return `https://lucid.app/documents/${
        this.process.ExternalDocumentId
      }/viewer?pages=${
        this.process.PageIndexes ? this.process.PageIndexes : 1
      }&token=${this.embedToken}`;
    },

    jiraParams() {
      return {
        query: {
          type: this.$appConfig.externalIssues,
          process: this.process ? this.process.IssueTrackingId : 0,
        },
      };
    },
    sortedVariants() {
      let variants = [...this.process.variants];
      return variants.sort((a, b) => (a.Number > b.Number ? 1 : -1));
    },
    tokensParams() {
      return {};
    },
    filteredJira() {
      if (
        !this.isFindJiraPending &&
        this.jiraLatestQuery &&
        this.jiraLatestQuery.response &&
        this.jiraLatestQuery.response.length > 0 &&
        this.jiraLatestQuery.response[0].issues
      ) {
        return this.jiraLatestQuery.response[0].issues;
      } else return null;
    },
    sharePointUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'SharePoint');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${
            this.$appConfig.customer.prefix
          }${this.process.Number.toString().padStart(4, '0')}`;
        }
      }
      return '';
    },
    jiraUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'Jira');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${this.process.IssueTrackingId}`;
        }
      }
      return '';
    },
  },
  async mounted() {
    if (this.$feature('lucidChartIntegration')) {
      await this.LucidEmbed();
    } else {
      // await this.getImages();
    }
  },
};
</script>

<style lang="scss">
.pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
.highlight {
  background-color: #ef9a9a;
}
</style>
