// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';
import { caslPlugin } from '@/store/vuex.plugin.casl'; // your previously defined file

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {
    currentProcess: {},
    activeEnd2End: {},
    drawer: false,
  },
  mutations: {
    updateCurrentProcess(state, process) {
      state.currentProcess = process;
    },
    setActiveEnd2End(state, end2end) {
      state.activeEnd2End = end2end;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
  },
  actions: {
    async setCurrentProcess({ commit }, process) {
      commit('updateCurrentProcess', process);
    },
  },
  plugins: [...servicePlugins, auth, caslPlugin],
});
