import vue from 'vue';

function handleSaveResponse(field, entity, type) {
  entity = entity[0].toUpperCase() + entity.slice(1);
  vue.toasted.global.success(`${entity} '${field}' ${type} successfully`);
}

function handleErrorResponse(error) {
  let errorMessage = '';
  if (
    Object.keys(error.errors).length === 0 &&
    error.errors.constructor === Object
  ) {
    errorMessage = error.message;
  } else {
    error.errors.map(item => {
      errorMessage += item.message + '\n';
    });
  }
  vue.toasted.global.error(errorMessage);
}

export { handleSaveResponse, handleErrorResponse };
