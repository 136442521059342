<template>
  <div class="ml-5 mr-5">
    <v-tooltip bottom v-if="processUrl">
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          class="ma-4"
          @click="
            openWindow(
              $feature('lucidChartIntegration') ? lucidUrl : processUrl
            )
          "
        >
          mdi-image-search-outline
        </v-icon>
      </template>
      <span>Process Flows</span>
    </v-tooltip>
    <v-data-table
      :headers="headers"
      :items="calculatedSteps"
      sort-by="Number"
      disable-pagination
      hide-default-footer
      item-key="id"
      show-select
      @item-selected="clickedRow"
      @toggle-select-all="clickedAll"
      v-model="importSelected"
      class="text-pre-wrap"
    >
      <template
        v-if="$can('update', 'test-scenario-steps')"
        v-slot:[`item.TestRemark`]="props"
      >
        <v-edit-dialog
          v-if="importSelected.filter((f) => f.id == props.item.id).length > 0"
          @save="saveRemark(props, 'TestRemark')"
          large
          >{{ props.item.TestRemark }}

          <template v-slot:input>
            <v-textarea
              v-model="props.item.TestRemark"
              label="Edit"
              single-line
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
      <template
        v-slot:[`item.ExpectedResults`]="props"
        v-if="$can('update', 'test-scenario-steps')"
      >
        <v-edit-dialog
          v-if="importSelected.filter((f) => f.id == props.item.id).length > 0"
          @save="saveRemark(props, 'ExpectedResults')"
          large
          >{{ props.item.ExpectedResults }}

          <template v-slot:input>
            <v-textarea
              v-model="props.item.ExpectedResults"
              label="Edit"
              single-line
            ></v-textarea>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  getDocumentEmbed,
  getDocumentEmbedToken,
} from '@/utils/LucidChartFunctions';
export default {
  data() {
    return {
      importSelected: [],
      embedToken: null,
      headers: [
        {
          text: 'Number',
          value: 'Number',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Name',
          value: 'Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Remark',
          value: 'TestRemark',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Expected Results',
          value: 'ExpectedResults',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    processStep: {
      type: Array,
      required: true,
    },
  },
  methods: {
    clickedAll(value, v2) {
      console.log(value, v2);

      value.items.forEach((v) => {
        v.item = v;
        v.value = value.value;
        this.clickedRow(v);
      });
    },
    clickedRow(value) {
      if (value.value == false) {
        this.item.TestScenarioSteps = this.item.TestScenarioSteps.filter(
          (f) => f.ProcessStepId != value.item.id
        );
      } else {
        this.item.TestScenarioSteps.push({
          TestScenarioId: this.item.id,
          ProcessStepId: value.item.id,
          ExpectedResults: value.item.ExpectedResults,
          TestRemark: value.item.TestRemark,
        });
      }
      this.importSelected = [];
      this.processStep.map((p) => {
        this.item.TestScenarioSteps.map((s) => {
          if (s.ProcessStepId == p.id) {
            p.TestRemark = s.TestRemark;
            p.ExpectedResults = s.ExpectedResults;
            // this.importSelected.filter((f) => f.id != s.ProcessStepId);
            this.importSelected.push(p);
          }
        });

        return p;
      });
    },
    saveRemark(props, field) {
      let item = this.item.TestScenarioSteps.find(
        (f) => f.ProcessStepId == props.item.id
      );
      if (item) item[field] = props.item[field];
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    async LucidEmbed() {
      if (
        this.item &&
        this.item.variant &&
        this.item.variant.process &&
        this.item.variant.process.ExternalDocumentId
      ) {
        if (!this.item.variant.process.EmbedId) {
          await getDocumentEmbed(this.item.variant.process);
          // console.log('No Embed');
        }
        this.embedToken = await getDocumentEmbedToken(
          this.item.variant.process
        );
        // console.log('Embed', this.embedToken);
      } else {
        // console.log('No document Linked');
      }
    },
  },
  computed: {
    calculatedSteps() {
      let calcSteps = [...this.processStep];
      return calcSteps.map((p) => {
        p.TestRemark = '';
        p.ExpectedResults = '';
        this.item.TestScenarioSteps.map((s) => {
          if (s.ProcessStepId == p.id) {
            p.TestRemark = s.TestRemark;
            p.ExpectedResults = s.ExpectedResults;
            this.importSelected.filter((f) => f.id == s.ProcessStepId).length ==
            0
              ? this.importSelected.push(p)
              : null;
          }
        });

        return p;
      });
    },
    processUrl() {
      let url = '';
      if (this.item && this.item.variant && this.item.variant.process) {
        url =
          'https://res.cloudinary.com/foocus/image/upload/fl_sanitize/v' +
          Date.now() +
          '/' +
          this.$appConfig.customer.prefix +
          '/' +
          this.item.variant.process.Number.toString() +
          '.' +
          this.item.variant.Number.toString();
      }
      return url;
    },

    lucidUrl() {
      if (this.item && this.item.variant && this.item.variant.process) {
        return `https://lucid.app/documents/${
          this.item.variant.process.ExternalDocumentId
        }/viewer?pages=${
          this.item.variant.PageIndexes
            ? this.item.variant.PageIndexes
            : this.item.variant.Number + 1
        }&token=${this.embedToken}`;
      }
      return null;
    },
  },
  async mounted() {
    // this.list = this.variant.process_steps.clone();
    if (this.$feature('lucidChartIntegration')) {
      await this.LucidEmbed();
    }
  },
};
</script>

<style lang="scss" scoped></style>
