var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-5"},[_c('v-row',[_c('v-col',[_c('v-toolbar-title',[_vm._v("Select Dependent tests")]),_c('v-treeview',{attrs:{"dense":"","hoverable":"","items":_vm.treeViewItems},scopedSlots:_vm._u([{key:"prepend",fn:function({ item }){return [(
              item.Type == 'variant' &&
              !_vm.activeParentTests.some((s) => s.variant.id == item.id)
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-format-list-text ")]):_vm._e(),(
              item.Type == 'variant' &&
              _vm.activeParentTests.some((s) => s.variant.id == item.id)
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-format-list-text ")]):_vm._e(),(
              item.Type == 'process' &&
              _vm.activeParentTests.some((s) => s.variant.process.id == item.id)
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-sitemap ")]):_vm._e(),(
              item.Type == 'process' &&
              !_vm.activeParentTests.some((s) => s.variant.process.id == item.id)
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-sitemap ")]):_vm._e(),(
              item.Type == 'test' &&
              _vm.itemtest.TestScenarioPreTests.find(
                (f) => f.ParentTestScenarioId == item.id
              ) == undefined
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-test-tube ")]):_vm._e(),(
              item.Type == 'test' &&
              _vm.itemtest.TestScenarioPreTests.find(
                (f) => f.ParentTestScenarioId == item.id
              ) != undefined
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"red"}},[_vm._v(" mdi-test-tube ")]):_vm._e()]}},{key:"label",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.Number)+" - "+_vm._s(item.Name))])]}},{key:"append",fn:function({ item }){return [(
              item.Type == 'test' &&
              _vm.itemtest.TestScenarioPreTests.find(
                (f) => f.ParentTestScenarioId == item.id
              ) == undefined
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.clickOnNode(item)}}},[_vm._v(" mdi-plus ")]):_vm._e(),(
              item.Type == 'test' &&
              _vm.itemtest.TestScenarioPreTests.find(
                (f) => f.ParentTestScenarioId == item.id
              ) != undefined
            )?_c('v-icon',{staticClass:"ma-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.clickOnNode(item)}}},[_vm._v(" mdi-minus ")]):_vm._e()]}}])})],1),_c('v-col',[_c('v-toolbar-title',[_vm._v("Dependent tests")]),_c('v-list',{attrs:{"dense":""}},_vm._l((this.activeParentTests),function(test){return _c('div',{key:test.id},[_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(test.Name))]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(test.variant.process.Name)+" > "+_vm._s(test.variant.Name))]),_vm._v(" "+_vm._s(test.Description)+" ")],1)],1)],1)}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }