<template>
  <v-dialog v-if="showDialog" v-model="showDialog" max-width="500px">
    <v-form ref="form">
      <v-card flat>
        <v-card-title>
          <span class="headline">Update Status</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                v-model="SelectedStatus"
                :items="status"
                item-text="Status"
                item-value="id"
                label="Status"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="cancelStatus()">Cancel</v-btn>
          <v-btn color="primary" @click="saveStatus()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import { handleErrorResponse } from '@/utils/MessageHandler';
export default {
  props: {
    value: { type: Boolean },
    VariantId: null,
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'process-step', watch: 'VariantId' }),
  ],
  data() {
    return {
      SelectedStatus: null,
    };
  },
  methods: {
    handleErrorResponse,
    async saveStatus() {
      if (this.processStep) {
        this.processStep.forEach((f) => {
          feathersClient
            .service('process-step')
            .patch(f.id, {
              StatusId: this.SelectedStatus,
            })
            .catch(async (error) => {
              handleErrorResponse(error);
            });
        });
      }
      const { Variant } = this.$FeathersVuex.api;
      await Variant.find({
        query: { id: this.VariantId },
      });
      this.showDialog = false;
    },
    cancelStatus() {
      this.showDialog = false;
    },
  },
  computed: {
    statusParams() {
      return {};
    },
    processStepParams() {
      return { query: { VariantId: this.VariantId } };
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
