var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"1100px"}},[_c('VariantEditDialog',{attrs:{"item":clone},on:{"save":function($event){_vm.showDialog = false;
          save()
            .then(async (saved) => {
              _vm.handleSaveResponse(
                saved.Name,
                'variant',
                _vm.currentItem ? 'updated' : 'created'
              );
              await _vm.refresh();
            })
            .catch(async (error) => {
              _vm.handleErrorResponse(error);
              await _vm.refresh();
            });},"reset":reset,"remove":function($event){remove()
            .then(async (saved) => {
              _vm.handleSaveResponse(saved.Name, 'variant', 'removed');
              await _vm.refresh();
            })
            .catch(async (error) => {
              _vm.handleErrorResponse(error);
              await _vm.refresh();
            });
          _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}})],1)]}}],null,false,2260451620)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }