<template>
  <v-container>
    <div v-if="id && variant" class="caption">
      <router-link
        v-if="$store.state.activeEnd2End.Name"
        :to="{
          name: 'Process',
          params: { id: $store.state.activeEnd2End.id },
        }"
        >E2E{{ $store.state.activeEnd2End.Number }} -
        {{ $store.state.activeEnd2End.Name }}</router-link
      ><v-icon v-if="$store.state.activeEnd2End.Name" small
        >mdi-chevron-right</v-icon
      >
      <router-link
        :to="{
          name: 'processDetail',
          params: { id: variant.process.id, number: variant.process.Number },
        }"
        >{{ $appConfig.customer.prefix
        }}{{ variant.process.Number.toString().padStart(4, '0') }} -
        {{ variant.process.Name }}</router-link
      >

      <v-menu open-on-hover bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" small
            >mdi-chevron-double-right</v-icon
          >
        </template>

        <v-list dense>
          <v-list-item v-for="variant in processVariants" :key="variant.id">
            <v-list-item-title
              ><router-link
                :to="{ name: 'processStep', params: { id: variant.id } }"
                >{{ variant.Number }}. {{ variant.Name }}</router-link
              ></v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>

      <router-link :to="{ name: 'processStep', params: { id: variant.id } }"
        >{{ variant.Number }} - {{ variant.Name }}</router-link
      >
      <v-icon small class="ml-2 mr-2">mdi-forward</v-icon>
      <router-link
        v-if="$feature('specification')"
        :to="{
          name: 'specification',
          params: { id: variant.id, type: 1 },
        }"
        ><b
          >Specifications ({{
            variant.specifications
              ? variant.specifications.filter(
                  (s) =>
                    s.specification_type.Active == true && s.Obsolete == false
                ).length
              : 0
          }}</b
        >)</router-link
      >
      <router-link
        v-else
        :to="{
          name: 'processRequirement',
          params: { id: variant.id, type: 1 },
        }"
        >Requirements ({{
          variant.requirements ? variant.requirements.length : 0
        }})</router-link
      >
      <v-icon v-if="sharePointUrl" small class="ml-2 mr-2">mdi-forward</v-icon>
      <a v-if="sharePointUrl" target="_blank" :href="sharePointUrl"
        >Documents</a
      >
    </div>
    <h1 v-if="id && variant">Test Scenarios for {{ variant.Name }}</h1>
    <h1 v-else>All Test Scenarios</h1>

    <v-tabs v-model="selectedTab" fixed-tabs active-class="activeTab">
      <v-tab> Define </v-tab>
      <v-tab> Execute </v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <v-card flat>
          <v-row justify="center">
            <v-col cols="12" md="10">
              <TestScenarioList :variant="variant" />
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-row justify="center">
            <v-col cols="12" md="12"> <TestScenarioRunList /> </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import TestScenarioList from '@/components/TestScenarios/TestScenarioList.vue';
import TestScenarioRunList from '@/components/TestScenarios/TestScenarioRunList.vue';
export default {
  components: { TestScenarioList, TestScenarioRunList },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      watch: 'id',
    }),
    makeFindMixin({
      service: 'variant',
      name: 'processVariants',
      watch: 'variant.ProcessId',
    }),
    makeFindMixin({
      service: 'tokens',
    }),
  ],
  data() {
    return {
      selectedTab: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    tokensParams() {
      return {};
    },
    sharePointUrl() {
      if (this.tokens) {
        let url = this.tokens.filter((t) => t.Application == 'SharePoint');
        if (url && url.length == 1) {
          return `${url[0].FrontEndUrl}${
            this.$appConfig.customer.prefix
          }${this.variant.process.Number.toString().padStart(4, '0')}`;
        }
      }
      return '';
    },
    processVariantsParams() {
      return {
        query: {
          ProcessId: this.variant ? this.variant.ProcessId : null,
          $sort: { Number: 1 },
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
