<template>
  <v-card width="100vw" flat>
    <v-data-table
      :headers="headers"
      :items="filteredComment"
      :items-per-page="-1"
      item-key="id"
      :loading="isFindCommentPending"
      class="elevation-1"
      width="1000"
    >
      <template v-slot:[`item.process_step`]="{ item }">
        <span
          class="caption"
          v-if="item.process_step && item.process_step.variant"
          >{{ item.process_step.variant.process.Name }}/
          <a
            :href="'/#/processStep/' + item.process_step.variant.id"
            v-if="item.process_step"
            >{{ item.process_step.variant.Name }}</a
          >({{ item.process_step.Number }})</span
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
export default {
  props: ['status', 'phase'],
  watch: {
    status: function () {
      const { Comment } = this.$FeathersVuex.api;
      Comment.find({
        query: { StatusId: this.status.StatusId },
      });
    },
  },
  mixins: [makeFindMixin({ service: 'comment' })],
  data() {
    return {
      headers: [
        {
          text: 'Process',
          align: 'start',
          sortable: false,
          value: 'process_step',
        },
        {
          text: 'Comment',
          align: 'start',
          sortable: true,
          value: 'Comment',
        },
      ],
    };
  },
  computed: {
    commentParams() {
      return { query: { StatusId: this.status.StatusId } };
    },
    filteredComment() {
      let filtered = [];
      if (this.phase == 'All') {
        filtered = this.comment;
      } else {
        filtered = this.comment.filter((ps) =>
          ps.process_step && ps.process_step.variant
            ? ps.process_step.variant.process.Phase == this.phase
            : true
        );
      }
      return filtered.sort((a, b) => {
        if (
          a.process_step &&
          a.process_step.variant &&
          a.process_step.variant.length > 0
        ) {
          return (
            a.process_step.variant.process.Number -
              b.process_step.variant.process.Number ||
            a.process_stepvariant.Number - b.process_step.variant.Number ||
            a.process_step.Number - b.process_step.Number
          );
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
