var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"my-1"},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[(_vm.variant[0])?_c('p',[_vm._v(_vm._s(_vm.variant[0].process.Input))]):_vm._e(),(_vm.variant[0])?_c('p',[_vm._v(_vm._s(_vm.variant[0].process.Output))]):_vm._e()]),_c('v-col',{attrs:{"cols":"12","md":"1"}},[(_vm.variantUrl)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",on:{"click":function($event){_vm.openWindow(
                _vm.$feature('lucidChartIntegration') ? _vm.lucidUrl : _vm.variantUrl
              )}}},'v-icon',attrs,false),on),[_vm._v(" mdi-image-search-outline ")])]}}],null,false,3222520719)},[_c('span',[_vm._v("Process Flows")])]):_vm._e()],1)],1),_c('v-tabs',{attrs:{"active-class":"activeTab"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.variant),function(item){return _c('v-tab',{key:item.id},[(
          (_vm.vendorResponseFinal(item) === 2 &&
            _vm.user.vendor.ResponsePhase == 1) ||
          (_vm.vendorResponseFinal2(item) === 2 && _vm.user.vendor.ResponsePhase == 2)
        )?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green","small":""}},[_vm._v("mdi-check-bold ")]):_vm._e(),(
          (_vm.vendorResponseFinal(item) === 0 &&
            _vm.user.vendor.ResponsePhase == 1) ||
          (_vm.vendorResponseFinal2(item) === 0 && _vm.user.vendor.ResponsePhase == 2)
        )?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red","small":""}},[_vm._v("mdi-close-thick")]):_vm._e(),(
          (_vm.vendorResponseFinal(item) === 1 &&
            _vm.user.vendor.ResponsePhase == 1) ||
          (_vm.vendorResponseFinal2(item) === 1 && _vm.user.vendor.ResponsePhase == 2)
        )?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"#FFC698","small":""}},[_vm._v("mdi-close-thick")]):_vm._e(),_vm._v(_vm._s(item.Name)+" ")],1)}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.variant),function(v){return _c('v-tab-item',{key:v.id},[_c('VariantResponse',{attrs:{"variant":v}})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }