<template>
  <div v-if="item">
    <v-dialog v-model="showDialog" width="1000" height="500">
      <v-card flat>
        <v-card-title>
          <span class="headline">Responses</span><v-spacer></v-spacer>
        </v-card-title>
      </v-card>

      <section>
        <v-data-table
          :headers="headers"
          :items="response"
          :items-per-page="15"
          :sort-by="['DateReceived']"
          :sort-desc="[true]"
          item-key="id"
          class="elevation-1"
        >
          <template v-slot:[`item.DateReceived`]="{ item }">
            <span>{{ new Date(item.DateReceived).toLocaleDateString() }}</span>
          </template>
        </v-data-table>
      </section>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  data() {
    return {};
  },
  mixins: [
    makeFindMixin({
      service: 'response',
      watch: 'item',
    }),
  ],
  props: {
    value: { type: Boolean },
    item: {},
  },
  computed: {
    responseParams() {
      return { query: { VariantId: this.item ? this.item.id : 0 } };
    },
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    filter() {
      return {
        query: {
          VariantId: this.item.id,
        },
      };
    },
    headers() {
      return [
        {
          text: 'Vendor',
          align: 'start',
          sortable: true,
          value: 'vendor.Name',
        },
        {
          text: 'Date Received',
          align: 'start',
          sortable: true,
          value: 'DateReceived',
        },
        {
          text: 'Implementation',
          align: 'start',
          sortable: true,
          value: 'implementation.Name',
        },
        {
          text: 'Complexity',
          align: 'start',
          sortable: true,
          value: 'complexity.Name',
        },
        {
          text: 'Remark',
          align: 'start',
          sortable: true,
          value: 'Remark',
        },
      ];
    },
  },
};
</script>

<style></style>
