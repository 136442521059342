<template>
  <section class="my-5" v-if="filteredTestScenarios">
    <v-row
      ><v-col>
        <v-text-field
          class="mb-5"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          clearable
          @click:clear="search = null"
        ></v-text-field></v-col
      ><v-col>
        <v-btn
          v-if="$can('create', 'testscenario')"
          :disabled="isFindTestScenarioPending || isFindProcessStepPending"
          elevation="0"
          class="mx-2 mt-2"
          fab
          small
          color="primary"
          @click="clickAdd()"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-col></v-row
    >

    <div class="mt-5">
      <v-row
        ><v-col
          v-for="test in filteredTestScenarios"
          :key="test.id"
          cols="12"
          md="6"
        >
          <v-card
            :loading="isFindTestScenarioPending || isFindProcessStepPending"
            height="100%"
          >
            <template slot="progress">
              <v-progress-linear
                indeterminate
                color="secondary"
              ></v-progress-linear>
            </template>

            <v-card-title class="subtitle-1"
              >{{ test.Name }} ({{ $appConfig.customer.prefix
              }}{{ test.FullNumber }})
              <v-icon
                small
                class="ml-5"
                color="primary"
                @click="clickEdit(test)"
                :disabled="
                  isFindTestScenarioPending || isFindProcessStepPending
                "
              >
                mdi-pencil </v-icon
              ><v-spacer></v-spacer>
              <v-icon
                v-if="!test.ValidInput && !test.Testable"
                disabled
                class="ml-5"
                color="#49BD91"
              >
                mdi-play-circle
              </v-icon>
              <v-icon
                v-if="test.ValidInput && !test.Testable"
                class="ml-5"
                color="#FFC698"
              >
                mdi-play-circle
              </v-icon>
              <v-icon
                v-if="test.ValidInput && test.Testable"
                class="ml-5"
                color="#49BD91"
                @click="confirmclick(test)"
              >
                mdi-play-circle
              </v-icon></v-card-title
            >
            <v-card-subtitle class="caption" v-if="!variant && test.variant"
              ><router-link
                :to="{
                  name: 'processDetail',
                  params: {
                    id: test.variant.process.id,
                    number: test.variant.process.Number,
                  },
                }"
                >{{ $appConfig.customer.prefix
                }}{{
                  test.variant.process.Number.toString().padStart(4, '0')
                }}
                - {{ test.variant.process.Name }}</router-link
              >

              <v-icon small>mdi-chevron-right</v-icon>
              <router-link
                :to="{ name: 'processStep', params: { id: test.variant.id } }"
                >{{ test.variant.Number }} -
                {{ test.variant.Name }}</router-link
              >
            </v-card-subtitle>

            <v-card-text class="ma-0"
              ><div class="text-pre-wrap">{{ test.Description }}</div>
              <div
                class="caption mt-2"
                v-if="
                  test.TestScenarioSteps &&
                  test.TestScenarioPreTests &&
                  test.TestScenarioSteps.length > 0
                "
              >
                {{ test.TestScenarioSteps.length }} process step{{
                  test.TestScenarioSteps.length > 1 ? 's' : ''
                }}
                to be tested<span
                  v-if="test.deps && test.deps.number_of_ancestors > 0"
                  >, depends on {{ test.deps.number_of_ancestors }} test{{
                    test.deps.number_of_ancestors > 1 ? 's' : ''
                  }}.
                </span>
                <span v-else>.</span>
                <ul v-if="test.deps && test.deps.number_of_ancestors > 0">
                  <span v-for="dep in test.deps.ancestry" :key="dep">
                    <li v-if="dep != test.id">
                      {{ testScenario.find((f) => f.id == dep).Name }}
                    </li>
                  </span>
                </ul>
              </div>
              <v-list dense>
                <v-list-item
                  v-for="fieldvalue in test.TestScenarioFieldValues"
                  :key="fieldvalue.id"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ fieldvalue.fieldvaluelist.Field.Name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{
                        fieldvalue.fieldvaluelist.Value
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card></v-col
        ></v-row
      >
    </div>

    <TestScenarioEdit
      v-if="detailDialog"
      v-model="detailDialog"
      :currentItem="currentItem"
      :variant="variant"
      :tests="testScenario"
      :processStep="processStep"
    />
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Add Test Run </v-card-title>
        <v-card-text
          >Testrun wil be added for {{ testrunItem ? testrunItem.Name : '' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="dialog = false"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="
              dialog = false;
              clickAddTestRun();
            "
          >
            Add Run
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay opacity="0" :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
        color="#000000"
      ></v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import TestScenarioEdit from '@/components/TestScenarios/TestScenarioEdit';
import { applyDark } from '@/utils/Utilities';
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  name: 'TestScenarioList',
  props: ['variant'],
  components: { TestScenarioEdit },
  mixins: [
    makeFindMixin({
      service: 'test-scenario',
    }),
    makeFindMixin({
      service: 'process-step',
    }),
    makeFindMixin({
      service: 'status',
    }),
  ],
  data: () => ({
    show: [],
    search: null,
    currentItem: null,
    detailDialog: false,
    loading: false,
    dialog: false,
    testrunItem: null,
  }),
  methods: {
    handleSaveResponse,
    handleErrorResponse,
    applyDark,
    clickEdit(item) {
      this.currentItem = item;
      this.detailDialog = true;
    },
    clickAdd() {
      this.currentItem = null; //{};
      this.detailDialog = true;
    },
    confirmclick(item) {
      this.dialog = true;
      this.testrunItem = item;
    },
    async clickAddTestRun() {
      this.loading = true;
      let testList = [];
      this.testrunItem.TestScenarioPreTests.forEach((i) => {
        let parentTest = this.testScenario.find(
          (f) => f.id == i.ParentTestScenarioId
        );

        while (parentTest) {
          // Check duplicate number for circular dependency
          if (testList.find((f) => f.id == parentTest.id)) {
            this.$toasted.global.error('Circular dependency detected');
            testList = [];
            throw 'exit';
          }
          testList.push(parentTest);
          // TODO what if multiple tests on a lower level. Now only first is used
          if (parentTest.TestScenarioPreTests.length > 0) {
            parentTest = this.testScenario.find(
              (f) =>
                f.id == parentTest.TestScenarioPreTests[0].ParentTestScenarioId
            );
          } else {
            parentTest = null;
          }
        }
      });
      console.log(testList);

      if (testList) {
        feathersClient
          .service('test-scenario-run')
          .create({})
          .then((saved) => {
            testList.forEach((t, i) => {
              feathersClient
                .service('test-scenario-run-detail')
                .create({
                  TestScenarioId: t.id,
                  TestScenarioRunId: saved.id,
                  Order: i + 1,
                })
                .then(async (savedRun) => {
                  t.TestScenarioSteps.forEach((s) => {
                    feathersClient
                      .service('test-scenario-run-steps')
                      .create({
                        TestScenarioRunDetailId: savedRun.id,
                        TestScenarioStepId: s.id,
                        Name: s.ProcessStep.Name,
                        StatusId: this.status[0].id,
                      })
                      .catch(async (error) => {
                        handleErrorResponse(error);
                      });
                  });

                  t.TestScenarioFieldValues.forEach((f) => {
                    feathersClient
                      .service('test-scenario-run-field-values')
                      .create({
                        TestScenarioRunDetailId: savedRun.id,
                        TestScenarioFieldValueId: f.id,
                        Name: f.fieldvaluelist.Value,
                      })
                      .catch(async (error) => {
                        handleErrorResponse(error);
                      });
                  });
                });
            });
            feathersClient
              .service('test-scenario-run-detail')
              .create({
                TestScenarioId: this.testrunItem.id,
                TestScenarioRunId: saved.id,
                Order: 0,
              })
              .then(async (savedRun) => {
                this.testrunItem.TestScenarioSteps.forEach((s) => {
                  feathersClient
                    .service('test-scenario-run-steps')
                    .create({
                      TestScenarioRunDetailId: savedRun.id,
                      TestScenarioStepId: s.id,
                      Name: s.ProcessStep.Name,
                      StatusId: this.status[0].id,
                    })
                    .catch(async (error) => {
                      handleErrorResponse(error);
                    });
                });

                this.testrunItem.TestScenarioFieldValues.forEach((f) => {
                  feathersClient
                    .service('test-scenario-run-field-values')
                    .create({
                      TestScenarioRunDetailId: savedRun.id,
                      TestScenarioFieldValueId: f.id,
                      Name: f.fieldvaluelist.Value,
                    })
                    .catch(async (error) => {
                      handleErrorResponse(error);
                    });
                });
              })
              .catch(async (error) => {
                handleErrorResponse(error);
              });
          })
          .catch(async (error) => {
            handleErrorResponse(error);
          });
        await this.refresh();
        this.loading = false;
      }
    },
    async refresh() {
      const { TestScenarioRun, TestScenarioRunDetail } = this.$FeathersVuex.api;
      await TestScenarioRunDetail.find({
        query: {},
      });
      await TestScenarioRun.find({
        query: {},
      });
    },
  },
  computed: {
    testScenarioParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    processStepParams() {
      return {
        query: {
          // VariantId: this.item.VariantId,
          $sort: { Number: 1 },
        },
      };
    },
    statusParams() {
      return { query: { $limit: 1, $sort: { Number: 1 } } };
    },
    filteredTestScenarios() {
      let filteredTests = this.testScenario;
      // if (filteredTests.variant) {
      //   filteredTests = filteredTests.sort((a, b) => {
      //     if (a.variant.process.Number === b.variant.process.Number) {
      //       return a.variant.Number - b.variant.Number;
      //     }
      //     return a.variant.process.Number - b.variant.process.Number;
      //   });
      // }
      if (this.variant) {
        filteredTests = filteredTests.filter(
          (item) => item.VariantId == this.variant.id
        );
      }
      if (this.search)
        return filteredTests.filter((item) =>
          item.Description
            ? item.Description.toLowerCase().includes(this.search.toLowerCase())
            : false ||
              item.Name.toLowerCase().includes(this.search.toLowerCase()) ||
              item.variant.Name.toLowerCase().includes(
                this.search.toLowerCase()
              ) ||
              item.variant.process.Name.toLowerCase().includes(
                this.search.toLowerCase()
              )
        );
      else return filteredTests;
    },
  },
};
</script>

<style lang="scss" scoped>
.obsolete {
  text-decoration: line-through;
}
.pre {
  white-space: pre-line;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
