<template>
  <v-container class="mt-10">
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card class="justify-center">
          <v-card-title class="justify-center">
            {{ $t('title.login') }}
          </v-card-title>
          <v-card-text class="justify-center">
            <v-row justify="center">
              <v-col cols="12" md="8">
                <form @submit.prevent="submit">
                  <v-text-field
                    v-model="user.email"
                    :error-messages="emailErrors"
                    :label="$t('form.email')"
                    required
                    autofocus
                  ></v-text-field>
                  <v-text-field
                    v-model="user.password"
                    :label="$t('form.password')"
                    type="password"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="$i18n.locale"
                    :items="langs"
                    item-text="Language"
                    item-value="Code"
                    :label="$t('form.language')"
                  ></v-select>
                  <v-card-actions class="mt-5 justify-center">
                    <v-btn @click="clear" outlined>
                      {{ $t('button.clear') }}
                    </v-btn>
                    <v-btn
                      type="submit"
                      class="mr-4"
                      @click="submit"
                      color="primary"
                    >
                      {{ $t('button.login') }}
                    </v-btn>
                  </v-card-actions>
                </form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="errorShow" timeout="2000">
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        strategy: 'local',
      },
      errorMessage: '',
      errorShow: false,
      langs: [
        { Language: 'English', Code: 'en' },
        { Language: 'Nederlands', Code: 'nl' },
        { Language: 'Français', Code: 'fr' },
      ],
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid e-mail');
      !this.$v.email.required && errors.push('E-mail is required');
      return errors;
    },
    ...mapGetters({ authenticated: 'auth/isAuthenticated' }),
  },
  methods: {
    async submit() {
      try {
        this.user.email = this.user.email.toLowerCase().trim();
        await this.$store.dispatch('auth/authenticate', this.user);
        this.$router.push('/');
      } catch (error) {
        this.errorMessage = error.message;
        this.errorShow = true;
      }
    },
    clear() {
      this.$v.$reset();
      this.user.email = '';
      this.user.password = '';
    },
  },
  mounted() {
    this.user.email = this.$route.query.email;
  },
};
</script>

<style lang="scss" scoped></style>
