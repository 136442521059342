var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('ProcessCommentEditDialog',{attrs:{"item":clone},on:{"save":function($event){_vm.showDialog = false;
        save()
          .then(async (saved) => {
            _vm.handleSaveResponse(
              saved.Comment,
              'comment',
              _vm.currentItem ? 'updated' : 'created'
            );
            await _vm.refresh();
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
          });},"reset":reset,"remove":function($event){remove()
          .then(async (saved) => {
            _vm.handleSaveResponse(saved.Comment, 'comment', 'removed');
            await _vm.refresh();
          })
          .catch(async (error) => {
            _vm.handleErrorResponse(error);
            await _vm.refresh();
          });
        _vm.showDialog = false;},"cancel":function($event){_vm.showDialog = false}}})]}}],null,false,3613325041)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }