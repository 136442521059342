<template>
  <span v-if="users && users[0]" class="caption"
    ><v-chip
      small
      :color="users[0].color"
      :text-color="applyDark(users[0].color) ? 'white' : 'black'"
      >Updated at {{ new Date(updateDate).toLocaleString() }} by
      <b class="ml-1"> {{ users[0].fullname }}</b>
    </v-chip></span
  >
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
export default {
  props: ['updateDate', 'userId', 'table', 'id'],
  mixins: [
    makeFindMixin({
      service: 'users',
    }),
  ],
  methods: {
    applyDark,
  },

  computed: {
    usersParams() {
      return { query: { id: this.userId } };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field input {
  font-size: 0.5em;
}
</style>
