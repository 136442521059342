var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-overlay',{attrs:{"value":_vm.generatingDocument}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('StatusSummary'),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(_vm.$can('create', 'end-2-end'))?_c('v-btn',{staticClass:"mx-2",attrs:{"elevation":"0","fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.clickAdd()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.end2End,"search":_vm.search,"sort-by":"Number","disable-pagination":"","hide-default-footer":"","loading":_vm.isFindEnd2EndPending,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.clickDetail(item)}}},[_vm._v(" mdi-chevron-right ")])]}},{key:`item.edit`,fn:function({ item }){return [(_vm.$can('update', 'end-2-end'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.clickEdit(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.$can('print', 'end-2-end'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.printingEnd2End(item)}}},[_vm._v(" mdi-printer-outline ")]):_vm._e()]}},{key:`item.status`,fn:function({ item }){return [(
                _vm.filteredStatus(item.id) && _vm.filteredStatus(item.id).length > 0
              )?_c('StatusListIndividual',{attrs:{"statusList":_vm.filteredStatus(item.id)}}):_vm._e()]}},{key:`item.issue`,fn:function({ item }){return [(_vm.filteredJira(item.Name))?_c('div',{on:{"click":function($event){return _vm.openTab(
                  `https://leah4hifferman.atlassian.net/issues/?jql=status != Closed AND 'End 2 End Process[Dropdown]' = '${item.Name}' order by created DESC`
                )}}},[(_vm.filteredJira(item.Name))?_c('JiraTasksProcess',{attrs:{"list":_vm.filteredJira(item.Name)}}):_vm._e()],1):_vm._e()]}},{key:`item.progress`,fn:function({ item }){return [(
                _vm.filteredStatus(item.id) && _vm.filteredStatus(item.id).length > 0
              )?_c('div',[_c('v-progress-linear',{attrs:{"value":(_vm.filteredStatus(item.id)[0].Progress * 100).toFixed(2),"height":"20","dark":"","color":"primary"}},[_c('strong',[_vm._v(_vm._s((_vm.filteredStatus(item.id)[0].Progress * 100).toFixed(2))+"%")])])],1):_vm._e()]}}],null,true)})],1)],1)],1),(_vm.detailDialog)?_c('End2EndEdit',{attrs:{"currentItem":_vm.currentItem},model:{value:(_vm.detailDialog),callback:function ($$v) {_vm.detailDialog=$$v},expression:"detailDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }