<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-5">
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer> </v-spacer>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="testScenarioRun"
            :search="search"
            sort-by="Number"
            class="text-pre-wrap"
            :loading="isFindTestScenarioRunPending"
            loading-text="Loading... Please wait"
            show-expand
            single-expand
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-data-table
                  :headers="headersDetail"
                  :items="item.TestScenarioRunDetail"
                  :sort-by="['Order']"
                  :sort-desc="[true]"
                  disable-pagination
                  hide-default-footer
                  class="text-pre-wrap"
                >
                  <template v-slot:[`item.RunTest`]="{ item }">
                    <v-icon
                      v-if="item.AllDone"
                      color="#49BD91"
                      @click="clickRun(item)"
                    >
                      mdi-play-circle
                    </v-icon>
                    <v-icon
                      v-if="!item.CanStart && !item.AllDone"
                      color="#CBCBCB"
                    >
                      mdi-play-circle
                    </v-icon>
                    <v-icon
                      v-if="item.CanStart && !item.AllDone"
                      color="#A5FFFE"
                      @click="clickRun(item)"
                    >
                      mdi-play-circle
                    </v-icon>
                    <span v-if="false"> {{ item }}</span>
                  </template></v-data-table
                >
              </td>
            </template>
            <template v-slot:[`item.TestNumber`]="{ item }">
              {{
                item.TestScenarioRunDetail && item.TestScenarioRunDetail.length
                  ? item.TestScenarioRunDetail.find((f) => f.Order == 0)
                      .TestScenario.FullNumber
                  : ''
              }}
            </template>
            <template v-slot:[`item.Test`]="{ item }">
              {{
                item.TestScenarioRunDetail && item.TestScenarioRunDetail.length
                  ? item.TestScenarioRunDetail.find((f) => f.Order == 0)
                      .TestScenario.Name
                  : ''
              }}
            </template>
            <template v-slot:[`item.CurrentTest`]="{ item }">
              {{
                item.TestScenarioRunDetail && item.TestScenarioRunDetail.length
                  ? item.TestScenarioRunDetail.filter(
                      (f) => f.CanStart && f.AllDone == false
                    ).length > 0
                    ? item.TestScenarioRunDetail.filter(
                        (f) => f.CanStart && f.AllDone == false
                      )[0].TestScenario.Name
                    : 'All Tests Done'
                  : ''
              }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <TestScenarioRun
      v-if="showDialog"
      :item="
        testScenarioRun
          .find((f) => f.id == currentItem.TestScenarioRunId)
          .TestScenarioRunDetail.find((f) => f.id == currentItem.id)
      "
      @cancel="showDialog = false"
    />
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import TestScenarioRun from '@/components/TestScenarios/TestScenarioRun.vue';
export default {
  data() {
    return {
      showDialog: false,
      currentItem: null,
      search: '',
      headers: [
        {
          text: 'Test Run',
          value: 'Number',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test Number',
          value: 'TestNumber',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Test',
          value: 'Test',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Current Test',
          value: 'CurrentTest',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Status',
          value: 'Status',
          sortable: false,
          groupable: false,
        },
        { text: '', value: 'data-table-expand' },
      ],
      headersDetail: [
        {
          text: 'Test Order',
          value: 'Order',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Number',
          value: 'TestScenario.FullNumber',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Name',
          value: 'TestScenario.Name',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Run Test',
          value: 'RunTest',
          sortable: false,
          groupable: false,
        },
      ],
    };
  },
  components: { TestScenarioRun },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-run',
    }),
  ],
  methods: {
    clickRun(item) {
      this.showDialog = true;
      this.currentItem = item;
    },
  },
  computed: {
    testScenarioRunParams() {
      return {};
    },
  },
};
</script>

<style lang="scss" scoped></style>
