<template>
  <v-card flat>
    <v-card-title v-if="$route.name != 'Comments'">
      <span class="headline"
        >Overview for status
        <v-chip
          class="ma-2"
          :color="status.Color"
          :text-color="applyDark(status.Color) ? 'white' : 'black'"
        >
          {{ status.Status }}
        </v-chip>
        (phase {{ phase }})</span
      ><v-spacer></v-spacer>
      <v-icon class="ml-5" @click="showDialog = false" color="primary">
        mdi-close-box
      </v-icon>
    </v-card-title>

    <v-card-text class="mt-5">
      <v-row justify="center" align="center">
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            <v-icon>mdi-arrow-decision</v-icon>
            Process Steps
          </v-tab>

          <v-tab href="#tab-2">
            <v-icon>mdi-pin</v-icon>
            Specifications
          </v-tab>
          <v-tab href="#tab-3">
            <v-icon>mdi-comment-processing-outline</v-icon>
            Comments
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item :value="'tab-1'">
            <v-row justify="center">
              <v-col cols="12" md="12">
                <v-card flat class="justify-center" min-height="600">
                  <v-card-text
                    ><ProcessPerStatus :status="status" :phase="phase"
                  /></v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-2'">
            <v-row justify="center">
              <v-col cols="12" md="12">
                <v-card flat class="justify-center" min-height="600">
                  <v-card-text
                    ><SpecificationPerStatus :status="status" :phase="phase"
                  /></v-card-text>
                </v-card> </v-col
            ></v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-3'">
            <v-row justify="center">
              <v-col cols="12" md="12">
                <v-card flat class="justify-center" min-height="600">
                  <v-card-text
                    ><CommentPerStatus :status="status" :phase="phase"
                  /></v-card-text>
                </v-card> </v-col
            ></v-row>
          </v-tab-item>
        </v-tabs-items> </v-row
    ></v-card-text>
  </v-card>
</template>

<script>
import ProcessPerStatus from './ProcessPerStatus';
import SpecificationPerStatus from './SpecificationPerStatus';
import CommentPerStatus from './CommentPerStatus';
import { applyDark } from '../utils/Utilities';

export default {
  props: { value: { type: Boolean }, status: {}, phase: {} },
  components: { ProcessPerStatus, SpecificationPerStatus, CommentPerStatus },
  data() {
    return {
      tab: 0,
    };
  },
  methods: {
    applyDark,
  },
  computed: {
    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
